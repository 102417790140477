import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal, ModalBody, CardTitle, CardSubtitle,
  Label
} from "reactstrap";

import Resizer from "react-image-file-resizer";
import Utils from '../Utility'
import { Cropper } from "react-cropper";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from 'react-select'
import { get, post, put, uploadS3 } from '../../helpers/api_helper'
import { toast } from "react-toastify";
import ProgressBar from "../../components/ProgressBar";
const API_URL = Utils.API_URL;

const AddShowPage = ({ currentData, onClosedClick, user }) => {
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);
  const [cropModal, setCropModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [catData, setCatData] = useState([]);
  const [progress, setProgress] = useState(null);

  const [type, setType] = useState('');

  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);


  let onProgress = (progress) => {
    setProgress(progress);
  }

  useEffect(async () => {
    if (currentData?.image) {
      setImg(currentData?.image);
    }
    setType(currentData?.type);
    await getTags();
    await getArtists();
    await getCatData();


  }, []);



  const getTags = async () => {
    let url = '/tags';

    await get(url, { token: user?.token })
      .then(json => {
        setTags(json?.data);
        if (currentData?.tags) {
          let temp = json?.data.filter(tag => currentData?.tags.includes(tag._id));
          setSelectedTags(temp.map(tempT => ({ value: tempT._id, label: tempT.title })))
        }
      }).catch(error => {
        console.log('error in fetching tag', error);
      })
  }


  const getCatData = async () => {
    let url = '/categories/list';

    await get(url, { token: user?.token })
      .then(json => {

        setCatData(json?.data);

      }).catch(error => {
        console.log('error in fetching cat', error);
      })
  }


  const getArtists = async () => {
    let url = '/artist/list';

    await get(url, { token: user?.token })
      .then(json => {

        setArtists(json?.data);

        if (currentData?.artists || currentData?.artists.length > 0) {
          console.log(currentData?.tags?.length);
          let temp = json?.data.filter(artist => currentData?.artists.includes(artist._id));
          console.log('pre selected artists will be', temp);
          setSelectedArtists(temp.map(tempA => ({ value: tempA._id, label: tempA.name })))
        }

        console.log('artistsList', json);

      }).catch(error => {
        console.log('error in fetching tags', error);
      })
  }




  const onChangeFile = async (e, type) => {

    // console.log(e.target.files[0]);
    const fileToSet = URL.createObjectURL(e.target.files[0]);
    setFile(fileToSet);
    console.log('onchangefile', fileToSet);
    setCropModal(true);
  }


  const options = tags.map(tag => ({
    value: tag._id,
    label: tag.title
  }));

  const options_artist = artists.map(artist => ({
    value: artist._id,
    label: artist.name
  }));

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });


  const uploadImage = async () => {
    if (file?.substring(0, 4) == "blob") {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      const temp = await resizeFile(fileObj);
      let data = {
        token: user?.token,
        folder: "story", type: "png"
      }
      // const imageRes = await upload("notification", temp, "webp", onProgress);

      const response = await fetch(API_URL + "/bucket", {
        method: 'PUT', // e.g., GET, POST, PUT, DELETE
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": "Bareer " + user?.token
        },
        body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
      })
      const res = await response.json();
      if (res?.statusCode === 200 && res.data) {
        console.log('Signed URL response: img', res?.data);
        // uploadToS3(res?.data, temp)
        try {
          // Fetch PUT request to upload the file
          const response = await fetch(res?.data, {
            method: 'PUT',
            headers: {
              'Content-Type': temp.type, // Set the content type of the file
            },
            body: temp, // The file to be uploaded
          });

          // Check for success
          if (response.status == 200) {
            console.log('File uploaded successfully to S3 img', response);
            return res.data.split("amazonaws.com/")[1].split("?")[0];
          } else {
            console.error('Failed to upload file:', response.status, response.statusText);
          }
        } catch (error) {
          console.error('Error during upload:', error);
        }
      } else {
        console.error("Failed to get signed URL:", res);
        return; // Exit function if signed URL generation fails
      }

      // const uploadedBanner = await uploadS3("story", image2, "webp", (progress) => {})
      // console.log('response from image upload', uploadedBanner);
      // if (uploadedBanner?.statusCode == 200){
      //   return uploadedBanner?.data
      // } else {
      //   throw 'There is some problem in uploading image to the server'
      // }
    }
  }


  const onChangeMedia = async (e) => {
    const fileToSet = URL.createObjectURL(e.target.files[0]);
    console.log('onchangemedia', fileToSet);

    if (type == 'audio') {
      setAudio(fileToSet);
    }
    if (type == 'video') {
      setVideo(fileToSet);
    }
  }



  const uploadMedia = async (type) => {
    console.log('inside upload media');
    if (type == 'audio') {
      if (audio?.substring(0, 4) == "blob") {
        let fileObj = await urltoFile(audio, (new Date().getTime() + 300) + '.mp3', 'audio/mp3');

        const uploadedBanner = await uploadS3("story", fileObj, "mp3", onProgress)
        console.log('response from media upload', uploadedBanner);
        if (uploadedBanner?.statusCode == 200) {
          return uploadedBanner?.data
        } else {
          throw 'There is some problem in uploading image to the server'
        }
      }
    }
    else {
      if (video?.substring(0, 4) == "blob") {
        let fileObj = await urltoFile(video, (new Date().getTime() + 300) + '.mp4', 'video/mp4');

        const uploadedBanner = await uploadS3("story", fileObj, "mp4", onProgress)
        console.log('response from media upload', uploadedBanner);
        if (uploadedBanner?.statusCode == 200) {
          return uploadedBanner?.data
        } else {
          throw 'There is some problem in uploading image to the server'
        }
      }
    }
  }

  const addData = async (e, v) => {
    try {
      console.log('story add data', v);


      let selectedTagsToSend = selectedTags.map(tag => (tag.value));
      let selectedArtistsToSend = selectedArtists.map(artist => (artist.value));

      console.log('tags to send ids', selectedTagsToSend);
      console.log('artist to send ids', selectedArtistsToSend);

      if (selectedTags.length == 0) {
        throw 'Please Select at least 1 tag';
      }
      if (selectedArtists.length == 0) {
        throw 'Please Select at least 1 Artist  ';
      }

      if (type == 'video' && v.orientation == '') {
        throw 'Please select a video orientation'
      }



      let body = {
        ...v,
        token: user?.token
      }

      if (v.catId == '') {
        throw 'Please select a Category';
      }
      if (v.type == '') {
        throw 'Please select a Content Type';
      }

      let imageUrl = await uploadImage();

      // let mediaUrl = await uploadMedia(currentData.type);


      body = {
        ...body,
        tags: selectedTagsToSend,
        artists: selectedArtistsToSend,
        image: imageUrl
      };

      if (type == 'audio') {
        if (audio?.substring(0, 4) == "blob") {
          let temp = await urltoFile(audio, (new Date().getTime() + 300) + '.mp3', 'audio/mp3');
          let data = {
            token: user?.token,
            folder: "story", type: "mp3"
          }
          // const imageRes = await upload("notification", temp, "webp", onProgress);

          const response = await fetch(API_URL + "/bucket", {
            method: 'PUT', // e.g., GET, POST, PUT, DELETE
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bareer " + user?.token
            },
            body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
          })
          const res = await response.json();
          if (res?.statusCode === 200 && res.data) {
            console.log('Signed URL response: audio', res?.data);
            // uploadToS3(res?.data, temp)
            try {
              // Fetch PUT request to upload the file
              const response = await fetch(res?.data, {
                method: 'PUT',
                headers: {
                  'Content-Type': temp.type, // Set the content type of the file
                },
                body: temp, // The file to be uploaded
              });

              // Check for success
              if (response.status == 200) {
                console.log('File uploaded successfully to S3 audio', response);
                body.url = res.data.split("amazonaws.com/")[1].split("?")[0];


              } else {
                console.error('Failed to upload file:', response.status, response.statusText);
              }
            } catch (error) {
              console.error('Error during upload:', error);
            }
          } else {
            console.error("Failed to get signed URL:", res);
            return; // Exit function if signed URL generation fails
          }
          // const uploadedBanner = await uploadS3("story", fileObj, "mp3", onProgress)
          // console.log('response from media upload', uploadedBanner);
          // if (uploadedBanner?.statusCode == 200) {
          //   return uploadedBanner?.data
          // } else {
          //   throw 'There is some problem in uploading image to the server'
          // }
        } else {
          if (currentData?._id)
            body.url = currentData?.url;
        }
      }
        else {
        if (video?.substring(0, 4) == "blob") {
          let temp = await urltoFile(video, (new Date().getTime() + 300) + '.mp4', 'video/mp4');

          // const uploadedBanner = await uploadS3("story", fileObj, "mp4", onProgress)
          // console.log('response from media upload', uploadedBanner);
          // if (uploadedBanner?.statusCode == 200) {
          //   return uploadedBanner?.data
          // } else {
          //   throw 'There is some problem in uploading image to the server'
          // }
          let data = {
            token: user?.token,
            folder: "story", type: "mp4"
          }
          // const imageRes = await upload("notification", temp, "webp", onProgress);

          const response = await fetch(API_URL + "/bucket", {
            method: 'PUT', // e.g., GET, POST, PUT, DELETE
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bareer " + user?.token
            },
            body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
          })
          const res = await response.json();
          if (res?.statusCode === 200 && res.data) {
            console.log('Signed URL response: video', res?.data);
            // uploadToS3(res?.data, temp)
            try {
              // Fetch PUT request to upload the file
              const response = await fetch(res?.data, {
                method: 'PUT',
                headers: {
                  'Content-Type': temp.type, // Set the content type of the file
                },
                body: temp, // The file to be uploaded
              });

              // Check for success
              if (response.status == 200) {
                console.log('File uploaded successfully to S3 video', response);
                body.url = res.data.split("amazonaws.com/")[1].split("?")[0];


              } else {
                console.error('Failed to upload file:', response.status, response.statusText);
              }
            } catch (error) {
              console.error('Error during upload:', error);
            }
          } else {
            console.error("Failed to get signed URL:", res);
            return; // Exit function if signed URL generation fails
          }
        }else {
          if (currentData?._id)
            body.url = currentData?.url;
        }
      }

      if (currentData?._id) {
        body = { ...body, contentId: currentData._id }
        put('/shows/update', body)
          .then(json => {
            if (json?.statusCode == 200) {
              toast.success(json?.message);
            }
          })
          .catch(error => {
            console.log('error while updating content', error);
            toast.error(error?.message ?? 'error');
          }).finally(() => {
            onClosedClick();
          });
      }
      else {
        post('/shows/add', body)
          .then(json => {
            if (json?.statusCode == 200) {
              toast.success(json?.message);
            }
          })
          .catch(error => {
            console.log('error while adding content', error);
            toast.error(error?.message ?? 'Error');
          }).finally(() => {
            onClosedClick();
          });
      }

    } catch (e) {
      console.log('error while adding content story data', e);
      toast.error(e);
    }
  }

  return (
    <div>
      <ProgressBar visible={progress > 0} progress={progress} />
      <Card>
        <CardBody>
          <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
            <div>
              <CardTitle className="h4">Add Story</CardTitle>
            </div>
            <div className="text-center" style={{ marginLeft: 'auto', cursor: 'pointer' }}>
              <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={onClosedClick}>
                Close
              </Button>

            </div>
          </div>
          <Modal isOpen={cropModal} style={{ maxWidth: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ModalBody style={{ padding: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h4>Crop Image</h4>
              <Cropper
                style={{ height: 'auto', width: '500px' }}
                aspectRatio={0.675}
                preview=".img-preview"
                guides={true}
                src={file}
                ref={cropper}
              />
              <Row style={{ width: '100%', marginTop: '22px', display: 'flex', justifyContent: 'space-around' }}>
                <Col md="auto">
                  <Button style={{ backgroundColor: Utils.themeColor, border: 'none', minWidth: '100px' }} onClick={() => setCropModal(false)}>
                    Cancel
                  </Button>
                </Col>
                <Col md="auto">
                  <Button style={{ backgroundColor: Utils.themeColor, border: 'none', minWidth: '100px' }} onClick={() => {
                    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
                    setImg(fileData);
                    setCropModal(false);
                  }}>
                    Ok
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {img ?
            <div className="d-flex justify-content-center">
              <div style={{ position: 'relative', width: 400, justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={img}
                  alt=""
                  style={{ width: 400, height: 'auto' }}
                // onClick={(e) => imageInput.current.click(e)}
                />

                <Button onClick={() => { setImg(null); setFile(null) }} style={{ borderRadius: '50px', height: '40px', width: '40px', position: 'absolute', top: -15, right: -15, backgroundColor: Utils.themeColor, border: 'none' }}>
                  <i className="mdi mdi-close" ></i>
                </Button>
              </div>
            </div>
            :
            <center>
              <div style={{
                alignContent: 'center',
                position: 'relative',
                // width: '40%',
                width: '250px',
                height: '250px',
                border: '1px dashed ' + Utils.themeColor,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: Utils.themeColor,
                fontSize: 22
              }} onClick={(e) => imageInput.current.click(e)}>
                <span
                  style={{
                    padding: 5,
                    paddingLeft: 15, paddingRight: 15,
                    border: '1px solid ' + Utils.themeColor,
                    borderRadius: 5
                  }}
                >Choose Image</span>
                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                >
                  <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                </div>
              </div>
            </center>
          }
          <input type="file" id="file"
            ref={imageInput}
            onChange={onChangeFile}
            style={{ display: "none" }} />

          <AvForm
            className="form-horizontal mt-5"
            onValidSubmit={addData}>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <AvField type="select" name="catId" label="Category" value={currentData?.catId} >
                    <option value={''} key={''}>Select Category</option>
                    {
                      catData?.map((item, index) => {
                        return (
                          <option value={item._id} key={item._id}>{item.title}</option>
                        )
                      })
                    }
                  </AvField>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField name='title' label='Title' value={currentData?.title} required />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>Tags</Label>
                  <Select
                    isMulti
                    options={options}
                    value={selectedTags}
                    label='Select Tags'
                    name="tags"
                    onChange={selectedOptions => {
                      console.log('selected', selectedOptions);
                      setSelectedTags(selectedOptions);
                    }}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>Select Artists</Label>
                  <Select
                    isMulti
                    options={options_artist}
                    label='Select Artists'
                    value={selectedArtists}
                    name="artists"
                    onChange={selectedOptions => {
                      console.log('artists selected', selectedOptions);
                      setSelectedArtists(selectedOptions);
                    }}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField type="select" name="type" label="Content Type" value={currentData?.type} onChange={(e) => {
                    console.log('now type', e.target.value);
                    setType(e.target.value)
                  }}>
                    <option value='' key=''>Select Content Type</option>
                    <option value='audio' key='audio'>Audio</option>
                    <option value='video' key='video'>Video</option>
                  </AvField>
                </div>
              </Col>
              {
                type == 'video' &&
                <Col md={6}>
                  <div className="mb-3">
                    <AvField type="select" name="orientation" label="Orientation (in case of video)" value={currentData?.orientation} onChange={(e) => {
                      console.log('now orientation', e.target.value);
                    }}>
                      <option value='' key=''>Select Video Orientation</option>
                      <option value='portrait' key='portrait'>Portrait</option>
                      <option value='landscape' key='landscape'>Landscape</option>
                    </AvField>
                  </div>
                </Col>
              }
              {/* <Col md={6}>
                <div className="mb-3">
                  <Label>Select Content Type</Label>
                  <Select
                    options={[
                      {
                        value : 'audio',
                        label : 'Audio' 
                      },
                      {
                        value : 'video',
                        label : 'Video' 
                      },
                    ]}
                    label='Content Type'
                    name="type"
                    onChange={selectedOption => {
                      console.log('artists selected', selectedOption);
                      setType(selectedOption.value);
                    }}

                    value={type}
                    required
                  />
                </div>
              </Col> */}
              <Col md={6}>
                <div className="mb-3">
                  <AvField name='desc' value={currentData?.desc} label='Description' type='textarea' rows={3} required />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField value={currentData?.amount} name='amount' label='Amount (in Coins)' type='number' required />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField value={currentData?.duration} name='duration' label='Duration (in Seconds)' type='number' required />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <AvField value={currentData?.scheduledAt || new Date()} name='scheduledAt' label='Schedule Date' 
                  type='datetime-local'/>
                </div>
              </Col>
              {
                type == 'video' &&
                <Col md={6}>

                  <AvField className="form-control"
                    type="file"
                    name="url"
                    label={currentData ? "Video File " : "Video File *"}
                    onChange={(e) => onChangeMedia(e, 'video')}
                    style={{ display: 'inline' }}
                    accept='video/mp4' />

                </Col>
              }
              {
                type == 'audio' &&
                <Col md={6}>

                  <AvField className="form-control"
                    type="file"
                    name="url"
                    label={currentData ? "Audio File " : "Audio File *"}
                    onChange={(e) => onChangeMedia(e, 'audio')}
                    style={{ display: 'inline' }}
                    accept='audio/mp3' />

                </Col>
              }
              <Col md={12}>
                <div className="d-flex justify-content-center mt-2">
                  <Button type="submit" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>{(currentData) ? 'Update Content' : 'Add Content'}</Button>
                </div>
              </Col>
            </Row>
          </AvForm>

        </CardBody>
      </Card>

    </div>
  )
}


export default AddShowPage;