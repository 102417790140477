import Utils from "../pages/Utility";
import AWS from 'aws-sdk';
// const API_URL = "https://mehandipurbalaji.app";
const API_URL = Utils.API_URL;

export async function get(url, data) {
  // return await axiosApi.get(url, { ...config }).then(response => response.data)
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())

}

export async function download(url, data) {
  // return await axiosApi.get(url, { ...config }).then(response => response.data)
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/zip',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    // body: JSON.stringify(data)
  }).then(res => res.blob())

}

export async function post(url, data) {
  console.log('post method req', url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
  // .post(API_URL+url, { ...data }, { ...config })
  // .then(response => response.data)
}

export async function put(url, data) {
  console.log('put method req', url, data)
  return fetch(API_URL + url, {
    method: 'PUT',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function upload(folder, data) {
  console.log('upload method req', process.env.REACT_APP_AWS_S3_KEY, process.env.REACT_APP_AWS_S3_SECRET, process.env.REACT_APP_AWS_S3_BUCKET_NAME, process.env.REACT_APP_AWS_S3_FOLDER_NAME);
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_S3_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET,
    region: "ap-south-1",
  });
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Body: data,
    Key: process.env.REACT_APP_AWS_S3_FOLDER_NAME + folder + "/" + data.name,
    ACL: 'public-read',
    ContentType: 'image/webp'
  };
  console.log("bucket params", params)
  const resp = await s3.upload(params).promise();
  console.log('response from s3', resp);
  if (resp)
    return {
      statusCode: 200,
      data: resp.Location,
      message: "SUCCESS"
    }
  else throw "error with img";
}

export async function uploadS3(folder, data, type, onProgress) {
  console.log("process.env ", process.env.REACT_APP_AWS_ACCESS_KEY_ID);
  const s3 = new AWS.S3({
    accessKeyId: "AKIAQ3EGU5PH235YPAFH",
    secretAccessKey:"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",
    region: "ap-south-1",
  });
  const params = {
    Bucket: "spiritualfm",
    Body: data,
    Key: "images/" + folder + "/" + Date.now() + "." + type,
    ACL: 'public-read'
  };
  console.log("bucket params", params);
  const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
  const managedUpload = s3.upload(params, options);
  managedUpload.on('httpUploadProgress', (progressData) => {
    const percentUploaded = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    onProgress(percentUploaded);
  });
  const resp = await managedUpload.promise();
  console.log('response from s3', resp);
  if (resp)
    return {
      statusCode: 200,
      data: resp.Location,
      message: "SUCCESS"
    }
  else throw "error with img";
}

export async function del(url, data) {
  return fetch(API_URL + url, {
    method: 'DELETE',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}
