import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import AddQuote from "../Forms/AddQuote";
import moment from "moment";

const Tags = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    if (!isAddForm)
      setCurrentData(null);
    getData();
  }, [isAddForm]);

  const getData = () => {
    get("/quote/list?type=Country", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200)
          setData(res?.data);
        else
          toast.error(res?.error);
      })
      .catch(err => {
        toast.error('Something Went Wrong!');
        setLoading(false);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          {row?.image ?
            <img src={row?.image} style={{ width: 63, height: 112 }} alt={row?.title} />
            :
            <span></span>
          }
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Date',
        cell: (row) => <>
          <span title={row?.date}>{row.date ? moment(row.date).format("DD-MMM")  : "-"}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Author',
        cell: (row) => <>
          <span title={row?.author}>{row.author ? row.author : "Unknown"}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Quote',
        cell: (row) => <>
          <span title={row?.quote}>{row.quote}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Likes',
        selector: row => row?.likes?.length,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleValidSubmit = (e, v) => {
    let body = {
      ...v,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, tagId: currentData?._id }
      put("/quote/tag/update", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/quote/tag/add", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      quoteId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/quote/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      quoteId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `/quote/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Country Quotes" />
        {isAddForm ?
          <AddQuote
            role={"Country"}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
          />
          :
          <Card>
            <CardBody>
              <Row>
                <DeleteModal
                  show={deleteModal}
                  onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                  onCloseClick={() => setDeleteModal(false)}
                  actionType={currentData?.actionType}
                  data={currentData}
                />
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                        <div>
                          <CardTitle className="h4">Country Quotes</CardTitle>
                          <CardSubtitle className="mb-3">
                            All Country Quotes you have added before. will be show on their date on after universal quote.
                          </CardSubtitle>
                        </div>
                        {user?.role == 'Admin' &&
                          <div className="text-center" style={{ marginLeft: 'auto' }}>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                              Add
                            </Button>
                          </div>
                        }
                      </div>
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{
                          headCells: {
                            style: {
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 15,
                              maxWidth: 80
                            },
                          },
                          cells: {
                            style: {
                              maxWidth: 50
                            }
                          }
                        }}
                        conditionalRowStyles={[{
                          when: row => row?.seen?.length < 1,
                          style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                        }]}
                      // onRowClicked={(r, e) => seenNoti(r)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Tags)
)