import React from 'react';
const loading = require('../../assets/images/loading.gif');
import { Audio } from 'react-loader-spinner'
import Utils from '../../pages/Utility'


const Loader = (props) => {
    if (props.visible)
        // if(true)
        return (
            <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff88', zIndex: 1051, top: 0, display: 'flex', position: 'absolute' }}>
                {/* <BallTriangle
                    height={100}
                    width={150}
                    radius={5}
                    color="#324fc4"
                    secondaryColor= '#4fa94d'
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle={{}}
                    visible={true}
                /> */}
                <Audio
                    height="50"
                    width="100"
                    radius="60"
                    color={Utils.themeColor}
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
                {/* <img src={loading} style={{height:100, width:100, backgroundColor:'transparent'}} />  */}
            </div>
        )
    else
        return null;
}

export default Loader;