import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal, ModalBody, CardTitle, CardSubtitle,
  Label
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from "react-data-table-component";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Rating } from "@mui/material";
import AddShowPage from "./AddShows";


const Shows = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  /// REVIEWS
  const [reviewModal, setReviewModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [reviewsColumns, setReviewsColumn] = useState([]);
  const [reviewsCurrentData, setReviewsCurrentData] = useState(null);
  const [reviewDeleteModal, setReviewDeleteModal] = useState(null);



  useEffect(() => {
    if (user && isAddForm == false) {
      getData();
    }
  }, [user, isAddForm]);

  const getData = () => {
    setLoading(true);
    let url = "/shows/list";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get shows list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting content admin list', error);
      }).finally(() => setLoading(false));
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 100, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: '150px'
      },
      {
        name: "Title",
        selector: row => row.title
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleReviewClick(row)}
            title={"View Pending Reviews"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-message-draw" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "200px"
      },
    ])
  }, [data]);

  useEffect(() => {
    setReviewsColumn([
      {
        name: "Review",
        selector: row => row.review,
        cell: (row) => <span title={row.review}>
          {row.review.length > 100 ? row.review.substring(0, 100) + '...' : row.review}
        </span>,

      },

      {
        name: "Rating",
        cell: (row) =>
          <>
            <Rating value={row?.rating} readOnly />
          </>,
        maxWidth: '140px'
      },

      {
        cell: (row) => <>
          <Button onClick={() => {
            handleReviewUpdateStatus(row);
          }}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "200px"
      },
      {
        cell: (row) => <>

          <Button onClick={() => handleReviewDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>

        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "75px"
      },
    ])
  }, [reviews])

  const handleReviewClick = (row) => {
    setCurrentData(row);
    setReviewModal(true);
  }

  const handleReviewUpdateStatus = (item) => {
    setReviewsCurrentData({ ...item, actionType: 'Status' });
    setReviewDeleteModal(true);
  }

  const handleReviewDeleteProj = (proj) => {
    setReviewsCurrentData({ ...proj, actionType: 'Delete' });
    setReviewDeleteModal(true);
  }

  const getReviewsByContentId = async () => {
    console.log('getReviewsByContentId');
    console.log(currentData?._id);


    get("/reviews?contentId=" + currentData?._id, { token: user?.token })
      .then(res => {
        // setLoading(false);
        if (res?.statusCode == 200) {
          setReviews(res?.data);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(error => {
        toast.error('Something Went Wrong!');
        console.log('error while getting reviews', error);
      })
  }


  const handleUpdateProj = (proj) => {
    console.log('currentData', proj)
    setCurrentData(proj);
    // setFile(proj?.image);
    // setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    // console.log('deleted', currentData);
    let body = {
      contentId: currentData?._id,
      token: user?.token
    }
    let url = "/content/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onDeleteReview = () => {
    // console.log('deleted', currentData);
    let body = {
      _id: reviewsCurrentData._id,
      contentId: currentData?._id,
      type: 'show',
      token: user?.token,
    }
    let url = "/reviews/update";
    if (reviewsCurrentData?.actionType == 'Status') {
      body = { ...body, isActive: !reviewsCurrentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getReviewsByContentId(currentData?._id);
          setReviewDeleteModal(false);
          setReviewsCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while updating', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });



  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/avatar/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Loader visible={loading} />
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Show" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        <Modal isOpen={reviewModal} toggle={() => setReviewModal(!reviewModal)} centered={true} style={{ maxWidth: '90%' }} onOpened={() => { getReviewsByContentId(); }} onClosed={() => { setReviews([]) }} >
          <ModalBody>
            <h5 className="text-black font-size-20">{'Reviews of ' + currentData?.title}</h5>
            <DataTable
              columns={reviewsColumns}
              data={reviews}
              pagination
              customStyles={{
                headCells: {
                  style: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 15,
                    maxWidth: 80
                  },
                },
                cells: {
                  style: {
                    maxWidth: 50
                  }
                }
              }}
            />
            <DeleteModal
              show={reviewDeleteModal}
              onDeleteClick={() => onDeleteReview()}
              onCloseClick={() => setReviewDeleteModal(false)}
              data={reviewsCurrentData}
            />
          </ModalBody>
          <Modal>

          </Modal>
        </Modal>
        {isAddForm ? <AddShowPage currentData={currentData} onClosedClick={() => { setIsAddForm(false); setCurrentData(null) }} user={user} />
          :
          <Card>
            <CardBody>
              <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                <div>
                  <CardTitle className="h4">{props.role}</CardTitle>
                  <CardSubtitle className="mb-3">
                    Show you have added before, All the <code> Shows </code>can be viewed by users on the App
                  </CardSubtitle>
                </div>
                <div className="text-center" style={{ marginLeft: 'auto' }}>
                  <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => { setIsAddForm(true) }}>
                    Add
                  </Button>
                </div>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{
                          headCells: {
                            style: {
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 15,
                              maxWidth: 80
                            },
                          },
                          cells: {
                            style: {
                              maxWidth: 50
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment >
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Shows)
)
