import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const imageToCrop = useRef(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [bannerHeight, setBannerHeight] = useState(256);
  const [error, setError] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);


  useEffect(() => {
    // console.log('props on user table', props)
    if (user) {
      getData();
    }
  }, [user, path]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setImg(null);
      setIsNavigate("");
      setNavigationType("")
    }
  }, [isAddForm]);

  const getData = () => {
    let url = "/coinpacks/list";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
   
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }

        console.log(json?.data[0])
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'No. of Coins',
        selector: row => row?.coins,
      },
      {
        name: 'Bonus Coins',
        selector: row => row?.extra,
        maxWidth : '150px'
      },
      {
        name: 'Price in INR',
        selector: row => row?.amount,
        maxWidth : '150px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Status")}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Recomm")}
            title={row?.recomm ? "Yes" : "No"}
            style={{ backgroundColor: !row.recomm ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.recomm ? 'Yes' : 'No'}
            </span>
          </Button>
        </>,
        name: 'Recommend',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/user/detail/` + row?._id, { state: { type: path } })}
            title={"View in details"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item, type) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: type });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      packId: currentData?._id,
      token: user?.token
    }
    let url = "/coinpacks/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == "Recomm") {
      body = { ...body, recomm: !currentData?.recomm || false };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
     
      const body = {
        ...v,
        token: user?.token,
        packId: currentData?._id
      }
      put("/coinpacks/update", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {

      const body = {
        ...v,
        token : user?.token
      };

      post("/coinpacks/add", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.messgae);
          setIsAddForm(false);
          getData();
        } else {
          toast.error(res?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error('Something Went Wrong!');
        console.log('Error While adding Coin Pack', error);
      })
    }
  }

  const handleStatusProj = (item) => {
    props?.setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/banner/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };


  const handleSelectLang = (code, lang) => {
    let temp = Object.assign([], selectedLang);
    let already = temp.findIndex(x => x.code == code);
    if (already == -1) {
      temp.push({ code: code, title: "", lang });
    } else {
      temp.splice(already, 1);
    }
    // console.log("temp", temp);
    setSelectedLang(temp);
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Label>
                    {currentData ? 'Update Coin Pack Details': 'Add Coin Pack'}
                  </Label>
                  <div style={{padding : '20px'}}>
                    <Col>
                      <div className="form-group mb-4">
                        <AvField
                          name="coins"
                          label={"Number of Coins"}
                          value={currentData?.coins}
                          className="form-control"
                          placeholder="Enter the Amount of Coins in this pack"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group mb-4">
                        <AvField
                          name="extra"
                          label={"Bonus Coins"}
                          value={currentData?.extra}
                          className="form-control"
                          placeholder="Enter the Extra Bonus Coins with this pack"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group mb-4">
                        <AvField
                          name="amount"
                          label={"Amount (in ₹)"}
                          value={currentData?.amount}
                          className="form-control"
                          placeholder="Enter the Amount in ₹"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <div className="text-center mt-4 d-flex justify-content-around">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Plan
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Plan
                        </Button>
                      }
                    </div>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>{role} </code>will reflect on your <code>Website and App.</code>
                    </CardSubtitle>
                  </div>
                  {!error &&
                    <>
                      {/* <div style={{ marginLeft: 'auto' }}>
                        <AvForm style={{ border: 'none' }}
                        >
                          <AvField
                            name="bannerHeight"
                            placeholder={"Banner Height"}
                            style={{ width: 150, border: "1px solid " + Utils.themeColor }}
                            validate={{ min: { value: 256 }, max: { value: 700 } }}
                            type="number"
                            value={bannerHeight}
                            onChange={(e) => handleBannerHeight(e.target.value)}
                          />
                        </AvForm>
                      </div> */}
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                    </>
                  }
                </div>
                <Row>
                  {/* {data?.map((item, index) => (
                    <Col lg={6} className="banner">
                      <div style={{ width: 'auto', height: bannerHeight ? bannerHeight - 100 : 360, display: 'flex' }}>
                        <img src={item?.image} style={{ width: 'auto', height: '100%' }} />
                        <div style={{ padding: 20, flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <p>Is Navigate : {item?.isNavigate}</p>
                            {item?.navigationType &&
                              <p>Navigation Type : {item?.navigationType}</p>
                            }
                            {item?.screenName &&
                              <p>Screen Name : {item?.screenName}</p>
                            }
                            {item?.link &&
                              <p>Link : {item?.link}</p>
                            }
                          </div>
                          <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div>
                              <div className="toggle-switch" style={{ marginLeft: -20 }}>
                                <input
                                  type="checkbox"
                                  className="toggle-switch-checkbox"
                                  name={"toggleSwitch" + index}
                                  id={"banner" + index}
                                  onChange={(e) => handleStatusProj(item)}
                                  checked={item.isActive}
                                />
                                <label className="toggle-switch-label" htmlFor={"banner" + index}>
                                  <span className="toggle-switch-inner" />
                                  <span className="toggle-switch-switch" />
                                </label>
                              </div>
                            </div>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none'}} onClick={() => handleUpdateProj(item)}>
                              <i className="mdi mdi-pencil" style={{ color: 'white', fontSize: 20, cursor: 'pointer' }} />
                            </Button>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => handleDeleteProj(item)}>
                              <i className="mdi mdi-delete" style={{ color: 'white', fontSize: 20, marginTop: 5, cursor: 'pointer' }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))} */}
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              },
                              cells: {
                                style: {
                                  maxWidth: 50
                                }
                              }
                            }}
                            conditionalRowStyles={[{
                              when: row => row?.seen?.length < 1,
                              style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                            }]}
                          // onRowClicked={(r, e) => seenNoti(r)}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
