import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';

const AddTheme = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [video, setVideo] = useState(undefined);
    const formRef = useRef();
    const imageInput = useRef();
    const cropper = useRef();
    const [weather, setWeather] = useState([]);
    const [selectedWeather, setSelectedWeather] = useState([]);
    const [cropperModal, setCropperModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [date, setDate] = useState(new Date());
    const [baseColor1, setBaseColor1] = useState("#000000");
    const [baseColor2, setBaseColor2] = useState("#077000");
    const [textColor, setTextColor] = useState("#ffffff");
    const [title, setTitle] = useState("");
    const [tagline, setTagline] = useState("");
    const [selectedLang, setSelectedLang] = useState([]);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values);
            let body = {
                ...values,
                title,
                tagline,
                alterLang: selectedLang,
                baseColor1: baseColor1.substring(1),
                baseColor2: baseColor2.substring(1),
                textColor: textColor.substring(1),
                token: user?.token
            }
            if (selectedWeather?.length < 1) {
                toast.error("Select atleast one weather!");
                return;
            }
            if (!file) {
                toast.error("Select a theme Image!");
                return;
            }
            if (!video) {
                toast.error("Select a theme video!");
                return;
            }
            let weathers = [];
            selectedWeather?.forEach(item => {
                weathers.push(item?._id);
            });
            body = { ...body, weathers };
            if (file.substring(0, 4) == 'data') {
                const obj = await urltoFile(file, new Date().getTime() + '.jpg', 'image/jpg');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const image = await upload("/theme/image_upload", form);
                body = { ...body, image: image?.data }
            }
            if (typeof video == "object") {
                const form = new FormData();
                form.append("video", video);
                const videoRes = await upload("/theme/video_upload", form);
                body = { ...body, video: videoRes?.data }
            }
            setLoading(true);
            if (data) {
                body = { ...body, themeId: data?._id }
                let url = "/theme";
                console.log('body after upload images', body);
                put(url, body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property updated');
                            toast.success(json?.message);
                            props.close(false);
                            // props?.getData();
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                console.log('body after upload images', body);
                post("/theme", body)
                    .then((json) => {
                        console.log('response from adding quote', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('product added');
                            toast.success(json?.message);
                            props.close(false);
                            // props?.getData();
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    })

            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        getWeather();
        if (data?.image)
            setFile(data?.image);
        if (data?.baseColor1)
            setBaseColor1("#" + data?.baseColor1);
        if (data?.baseColor2)
            setBaseColor2("#" + data?.baseColor2);
        if (data?.textColor)
            setTextColor("#" + data?.textColor);
        if (data?.title)
            setTitle(data?.title);
        if (data?.tagline)
            setTagline(data?.tagline);
        if (data?.video)
            setVideo(data?.video);
        if (data?.alterLang?.length) {
            let temp = [];
            data?.alterLang?.forEach(element => {
                user?.languages?.forEach((item) => {
                    if (element?.code == item?.code)
                        temp.push({ ...element, lang: item?.title });
                });
            });
            setSelectedLang(temp);
        }
    }, [data]);

    useEffect(() => {
        if (data?.weathers?.length > 0 && weather?.length > 0) {
            let selected = [];
            weather?.forEach(item => {
                data?.weathers?.forEach(we => {
                    if (item?._id == we)
                        selected.push(item);
                })
            });
            setSelectedWeather(selected);
        }
    }, [data, weather]);

    const getWeather = async () => {
        get("/theme/weather")
            .then(res => {
                if (res?.statusCode == 200)
                    setWeather(res?.data);
            })
    }

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        setFile(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    function formatMonth(monthNumber) {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return 'Invalid Month';
        }
    }

    const handleSelectLang = (code, lang) => {
        let temp = Object.assign([], selectedLang);
        let already = temp.findIndex(x => x.code == code);
        if (already == -1) {
            temp.push({ code: code, title: "", tagline: "", lang });
        } else {
            temp.splice(already, 1);
        }
        // console.log("temp", temp);
        setSelectedLang(temp);
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <h4 className="card-title">{data ? 'Update ' : 'Add a '} Theme</h4>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardBody>
                        <Row className='p-3'>
                            {file ?
                                <Col lg={6} md={6} style={{}}>
                                    <div className="me-3 position-relative" style={{ width: 315, height: 560 }}>
                                        <img
                                            src={file ? file : avatar}
                                            alt=""
                                            style={{ width: '100%', height: '50%' }}
                                        />
                                        <div style={{ background: `linear-gradient(0deg, ${baseColor2} 0%, ${baseColor1} 70%, ${baseColor1 + "00"} 100%)`, height: '80%', width: '100%', position: 'relative', zIndex: 1, marginTop: '-130px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                            <div style={{ height: '65%', bottom: 0, width: '100%', padding: 10 }}>
                                                <h3 style={{ color: textColor + "aa", fontFamily: 'Aboreto', textAlign: 'center' }}>{title}</h3>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 0, color: textColor + "88" }}><p style={{ fontSize: 68, fontFamily: 'Aboreto', marginBottom: 0 }}>“</p><p style={{ marginTop: -12, fontSize: 12, marginBottom: 0 }}>{tagline}</p><p style={{ fontSize: 68, fontFamily: 'Aboreto', marginBottom: 0 }}>”</p></div>
                                                <button style={{ width: '100%', padding: 12, background: baseColor2, borderRadius: 50, color: '#fff', border: 'none', outline: 'none' }}>Select Essence</button>
                                            </div>
                                        </div>
                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            onClick={(e) => setFile(null)}
                                        >
                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                        </div>
                                    </div>
                                </Col>
                                :
                                <Col lg={6} md={6}>
                                    <div className="me-3 position-relative" style={{ width: 315, height: 560 }}>
                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                            onClick={(e) => imageInput.current.click(e)}
                                        >
                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            >
                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                            <input type="file" id="file"
                                                ref={imageInput}
                                                onChange={(e) => onChangeFile(e, 'file')}
                                                style={{ display: "none" }} />
                                        </div>
                                    </div>
                                </Col>
                            }
                            <Col lg={6} md={6}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                    ref={formRef}
                                >
                                    <Col md={12}>
                                        <div className="form-group mb-4">
                                            <Label>Weathers *</Label>
                                            <Multiselect
                                                options={weather}
                                                selectedValues={selectedWeather}
                                                onSelect={(list, item) => setSelectedWeather(list)}
                                                displayValue="title"
                                            />
                                        </div>
                                    </Col>

                                    <Row>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="baseColor1"
                                                    label="Gradient One *"
                                                    value={baseColor1}
                                                    className="form-control"
                                                    placeholder="Enter gradient color one"
                                                    type="color"
                                                    onChange={(e) => setBaseColor1(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="baseColor2"
                                                    label="Gradient Two *"
                                                    value={baseColor2}
                                                    className="form-control"
                                                    placeholder="Enter gradient color two"
                                                    type="color"
                                                    onChange={(e) => setBaseColor2(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="textColor"
                                                    label="Text Color *"
                                                    value={textColor}
                                                    className="form-control"
                                                    placeholder="Enter text color"
                                                    type="color"
                                                    onChange={(e) => setTextColor(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="isPaid"
                                                    label="Content Paid Type *"
                                                    className="form-control"
                                                    type="select"
                                                    value={data ? String(data?.isPaid) : ""}
                                                    required
                                                >
                                                    <option value={""}>Select Paid Type</option>
                                                    <option value={"false"} >Free</option>
                                                    <option value={"true"} >Paid</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md={8}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="videoId"
                                                    label="Video *"
                                                    className="form-control"
                                                    placeholder="Enter text color"
                                                    type="file"
                                                    accept="video/mp4"
                                                    onChange={(e) => setVideo(e.target.files[0])}
                                                    required={data ? false : true}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="title"
                                                    label="Title *"
                                                    value={title}
                                                    className="form-control"
                                                    placeholder="Enter theme title"
                                                    type="text"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="tagline"
                                                    label="tagline *"
                                                    value={tagline}
                                                    className="form-control"
                                                    placeholder="Enter tagline"
                                                    type="text"
                                                    onChange={(e) => setTagline(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Label>Alternative Languages</Label>
                                    <Row>
                                        <div className='lang-checks mb-3'>
                                            {user?.languages?.map((item) => (
                                                <div className='check-group'>
                                                    <label for={item?.title} class="container">
                                                        {item?.title}
                                                        <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {selectedLang?.map((item, index) => (
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={"title" + item?.lang}
                                                            label={"Title in " + item?.lang}
                                                            className="form-control"
                                                            placeholder={"Enter title in " + item?.lang}
                                                            type="text"
                                                            onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                            required
                                                            value={item?.title}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={"tagline" + item?.lang}
                                                            label={"Tagline in " + item?.lang}
                                                            className="form-control"
                                                            placeholder={"Enter tagline in " + item?.lang}
                                                            type="textarea"
                                                            rows={4}
                                                            onChange={(e) => handleAlterTitle(index, "tagline", e.target.value)}
                                                            required
                                                            value={item?.tagline}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Row>
                                    <Col lg={12} md={12}>
                                        <div className="text-center mt-4">
                                            {data ?
                                                <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                    Update {role}
                                                </Button>
                                                :
                                                <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                    Add {role}
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </AvForm>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddTheme.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddTheme)
)
