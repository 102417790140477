import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { Link } from 'react-router-dom';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
// import { toast } from 'react-toastify';
import { toast } from 'react-hot-toast';
import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment/moment";
import UserProfile from "../../components/UserProfile";
// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, loading, setLoading, view } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userType, setUserType] = useState("Registered");
  const [userTime, setUserTime] = useState(props?.userTime);
  const [userTimeType, setUserTimeType] = useState(props?.userTimeType);
  const [currentUser, setCurrentUser] = useState(null);
  const [notiModal, setNotiModal] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteType, setDeleteType] = useState(undefined);
  const [notiType, setNotiType] = useState("individual");
  const [notiData, setNotiData] = useState(undefined);
  const [profileModal, setProfileModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (user?.token) {
      getData(0);
    }
  }, [user, userType, search, selectedDate]);

  useEffect(() => {
    if (user?.token)
      getScreens();
  }, [user])

  useEffect(() => {
    if (props?.userType)
      setUserType(props?.userType);
  }, [props?.userType]);

  useEffect(() => {
    if (props?.userTime)
      setUserTime(props?.userTime);
  }, [props?.userTime]);

  const getData = (currentPage) => {
    if (!loading) {
      setLoading(true);
      let url = "/user/list?type=" + userType + "&search=" + search + "&date=" + selectedDate + "&currentPage=" + currentPage;
      get(url, { token: user?.token })
        .then(json => {
          setLoading(false);
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setTotalPage(json?.totalPage);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  const getScreens = () => {
    if (user?.token)
      get("/screen/list", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setScreens(res?.data);
        })
  }

  useEffect(() => {
    if (userType == 'Unregistered') {
      setColumns([
        {
          name: 'Device Id',
          selector: row => row?.deviceId,
          sortable: true
        },
        {
          name: 'OS',
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{row?.deviceType}</span>
          </>,
          selector: row => row?.deviceType,
          sortable: true
        },
        {
          name: 'Last Active',
          selector: row => row?.lastSeen,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment(row?.lastSeen).fromNow()}</span>
          </>,
          sortable: true
        },
        {
          name: 'Registered On',
          selector: row => row?.createdAt,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment(row?.createdAt).format("DD MMM YYYY, hh:mm a")}</span>
          </>,
          sortable: true
        },
        {
          cell: (row) => <>
            <>
              <Button
                title={"Analytic"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><Link target="_blank" to={row?.deviceId ? "/anayltic/user/" + row?.deviceId : ""}><span className={"mdi mdi-finance"} style={{ fontSize: 20, color: 'white' }}></span></Link></Button>
              {row?.fcmToken &&
                <Button onClick={() => handleSendNoti(row)}
                  title={"Send Notification"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isLastNoti == false ? "mdi mdi-bell-off" : "mdi mdi-bell"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              }
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: row?.isBanned ? 'red' : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isBanned ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ fontSize: 20 }}></span></Button>
            </>
          </>,
          name: 'Action',
          selector: row => row?.isLastNoti,
          sortable: true
        }
      ]);
    } else {
      setColumns([
        {
          name: 'Name',
          selector: row => row?.name,
          cell: (row) => <div>
            <i className={row?.deviceType == 'android' ? "mdi mdi-android" : "mdi mdi-apple-ios"} style={{ fontSize: 18 }} />
            <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.name}</span>
          </div>,
          sortable: true,
          maxWidth: '195px'
        },
        {
          name: 'Email',
          selector: row => row?.email,
          sortable: true
        },
        {
          name: 'Mobile',
          selector: row => row?.mobile,
          sortable: true,
          maxWidth: '110px'
        },
        {
          name: 'Last Active',
          selector: row => row?.lastSeen,
          cell: (row) => <>
            <span style={{}}>{moment(row?.lastSeen).parseZone('Asia/Kolkata').fromNow()}</span>
          </>,
          sortable: true,
          maxWidth: '135px'
        },
        {
          name: 'Registered On',
          selector: row => row?.createdAt,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment(row?.createdAt).format("DD MMM YYYY, hh:mm A")}</span>
          </>,
          sortable: true,
          maxWidth: '170px'
        },
        {
          cell: (row) => <>
            <>
              <Button
                title={"Analytic"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><Link target="_blank" to={row?.deviceId ? "/anayltic/user/" + row?.deviceId : ""}><span className={"mdi mdi-finance"} style={{ fontSize: 20, color: 'white' }}></span></Link></Button>
              <Button
                onClick={() => { setCurrentData(row); setProfileModal(true) }}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-eye"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              {row?.fcmToken &&
                <Button onClick={() => handleSendNoti(row)}
                  title={"Send Notification"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isLastNoti == false ? "mdi mdi-bell-off" : "mdi mdi-bell"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              }
              <Button onClick={() => handleDeleteProj(row)}
                title={row?.isBanned ? "Allow" : "Ban"}
                style={{ backgroundColor: row?.isBanned ? 'red' : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isBanned ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ fontSize: 20 }}></span></Button>
            </>
          </>,
          name: 'Action',
          ignoreRowClick: true,
          maxWidth: '190px'
        }
      ]);
    }
  }, [data, userType]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleSendNoti = (proj) => {
    // console.log('sending noti to ', proj);
    setCurrentUser(proj);
    setNotiModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      userId: currentData?._id,
      isBanned: !currentData?.isBanned,
      token: user?.token
    }
    let url = "/user/delete";
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData(0);
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = (e, v) => {
    setLoading(true);
    let body = {
      ...v,
      fcmToken: currentUser?.fcmToken,
      token: user?.token
    }
    console.log('handling send notification to user!', body);
    let url = "/notification/send_to_user";
    if (notiType == "uninstall")
      url = "/notification/send_to_unregistered"
    post(url, body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsNavigate("");
          setNavigationType("");
          setCurrentUser(null);
          setNotiModal(false);
          setNotiType("individual");
          getData(0);
        } else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error while adding money to user account!', err);
        setLoading(false);
      })
  }

  const handleRemoveUninstalled = () => {
    console.log('removing uninstalled user');
    setLoading(true);
    get("/user/remove_uninstall", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getData(0);
          setDeleteModal(false);
          setDeleteType(undefined);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error("Something went wrong! " + err);
      })
  }

  const handleSendNotiToUnregistered = () => {
    // send_to_unregistered
    setNotiType("uninstall");
    setIsNavigate("Yes");
    setNavigationType("internalPage")
    setNotiData({ title: "प्रिय उपयोगकर्ता,", body: "हमें खेद है कि आपने अब तक मेहंदीपुर बालाजी ऐप के सभी सुविधाओं का उपयोग नहीं किया है। हम आपको ऐप के मज़ेदार और उपयोगी फ़ीचर्स का आनंद लेने की सलाह देते हैं। अभी लॉगिन करने के लिए टैप करे। ", screenName: "login" });
    setNotiModal(true);
  }

  const handlePerRowsChange = (newPerPage, page) => {
    console.log('pagi', newPerPage, page);
  }

  const handlePageChange = (page) => {
    console.log('paginat', page);
    getData((page - 1) * 100);
  }

  const handleDownload = async () => {
    // get("", { token: user?.token });
    try {
      const response = await fetch(Utils.API_URL + '/user/download_list');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => deleteType == "Uninstalled" ? handleRemoveUninstalled() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
            deleteType={deleteType}
          />
          {profileModal &&
            <UserProfile
              show={profileModal}
              onCloseClick={() => setProfileModal(false)}
              userId={currentData?._id}
            />
          }
          <Modal className="modal_form" isOpen={notiModal} toggle={() => { setNotiModal(false); setCurrentUser(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{'Send Instant Notification to ' + currentUser?.name}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="isNavigate"
                          label={"Navigation"}
                          value={isNavigate}
                          className="form-control"
                          type="select"
                          required
                          onChange={e => setIsNavigate(e.target.value)}
                        >
                          <option value="">Select Navigation</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </AvField>
                      </div>
                    </Col>
                    {isNavigate == 'Yes' &&
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="navigationType"
                            label={"Select Navigation Type"}
                            value={navigationType}
                            className="form-control"
                            type="select"
                            onChange={(e) => setNavigationType(e.target.value)}
                            required
                          >
                            <option value="">Select Navigation Type</option>
                            <option value="internalPage">Internal App Screen</option>
                            <option value="inApp">In App Link</option>
                            <option value="outApp">External Web Link</option>
                          </AvField>
                        </div>
                      </Col>
                    }
                    {isNavigate == 'Yes' &&
                      <>
                        {navigationType == 'internalPage' ?
                          <Col lg={6} md={6}>
                            <div className="form-group mb-4">
                              <AvField
                                name="screenName"
                                label={"Select Navigation Type"}
                                value={notiData?.screenName}
                                className="form-control"
                                type="select"
                                required
                              >
                                <option value="">Select App Screen</option>
                                {screens?.map((item) => (
                                  <option value={item?.for}>{item?.title}</option>
                                ))}
                              </AvField>
                            </div>
                          </Col>
                          :
                          <Col lg={12} md={12}>
                            <div className="form-group mb-4">
                              <AvField
                                name="link"
                                label={"Web URL"}
                                value={notiData?.link}
                                className="form-control"
                                placeholder={`Enter Url you need to open`}
                                type="url"
                                required
                              />
                            </div>
                          </Col>
                        }
                      </>
                    }
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="title"
                          label="Notification Title"
                          className="form-control"
                          placeholder="Enter something about your notification"
                          type="text"
                          required
                          value={notiData?.title}
                        // validate={{min:{value:1}}}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="body"
                          label="Notification Description"
                          className="form-control"
                          placeholder="Enter detailed notification"
                          type="textarea"
                          rows={4}
                          required
                          value={notiData?.body}
                        // validate={{min:{value:1}}}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              onClick={() => { setNotiModal(false); setCurrentUser(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            >
                              Send
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12 usertable">
            <Card>
              <CardBody>
                <Row>
                  <Col md={10}>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                    </CardSubtitle>
                  </Col>
                  <Col md={2}>
                    <h5>Users ({totalPage})
                      <Button style={{ backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}
                        onClick={handleDownload}
                      ><i className="mdi mdi-file-excel" style={{ fontSize: 18 }} /></Button>
                    </h5>
                  </Col>
                </Row>
                <Row className="my-3">
                  {userType == 'Registered' &&
                    <Col md={8}>
                      <AvForm>
                        <div>
                          <AvField
                            name="search"
                            label="Search"
                            placeholder="Search by name, email, mobile"
                            type="text"
                            autoComplete="off"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          >
                          </AvField>
                        </div>
                      </AvForm>
                    </Col>
                  }
                  {userType == 'Unregistered' &&
                    <Col md={8}>
                      <div style={{ marginTop: 20 }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={handleSendNotiToUnregistered}
                        >
                          Send Login Notification
                        </Button>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none', marginLeft: 10 }}
                          onClick={() => { setDeleteType("Uninstalled"); setDeleteModal(true) }}
                        >
                          Remove Uninstalled Users
                        </Button>
                      </div>
                    </Col>
                  }
                </Row>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  // paginationComponent={CustomPagination}
                  // pagination
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[100, 200, 300, 400]}
                  // paginationComponentOptions={{ selectAllRowsItem: true }}
                  paginationServer
                  paginationTotalRows={totalPage}
                  // onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default Table;
