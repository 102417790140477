import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal, ModalBody, CardTitle, CardSubtitle
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import { AvForm, AvField } from "availity-reactstrap-validation";
import DataTable from "react-data-table-component";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const API_URL = Utils.API_URL;
const Avatar = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    let url = "/avatar/list";
    get(url, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 100, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    // console.log('deleted', currentData);
    let body = {
      avatarId: currentData?._id,
      token: user?.token
    }
    let url = "/avatar";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
      const body = {
        ...v,
        token: user?.token,
        avatarId: currentData?._id
      }
      if (file.substring(0, 4) == "blob") {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const temp = await resizeFile(obj);
        let data = {
          token: user?.token,
          folder: "avatar", type: "png"
        }
        // const imageRes = await upload("notification", temp, "webp", onProgress);

        const response = await fetch(API_URL + "/bucket", {
          method: 'PUT', // e.g., GET, POST, PUT, DELETE
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bareer " + user?.token
          },
          body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
        })
        const res = await response.json();
        if (res?.statusCode === 200 && res.data) {
          console.log('Signed URL response:', res?.data);
          // uploadToS3(res?.data, temp)
          try {
            // Fetch PUT request to upload the file
            const response = await fetch(res?.data, {
              method: 'PUT',
              headers: {
                'Content-Type': temp.type, // Set the content type of the file
              },
              body: temp, // The file to be uploaded
            });

            // Check for success
            if (response.status == 200) {
              body.image = res.data.split("amazonaws.com/")[1].split("?")[0];
              console.log('File uploaded successfully to S3', response);
            } else {
              console.error('Failed to upload file:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Error during upload:', error);
          }
        } else {
          console.error("Failed to get signed URL:", res);
          return; // Exit function if signed URL generation fails
        }
      }

      put("/avatar", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating avatar', error);
        })
    } else {
      if (file) {
        let image = "";
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const temp = await resizeFile(obj);
        // const imageRes = await upload("avatar", temp);
        // image = imageRes?.data;
        const body = {
          ...v,
          token: user?.token
        }
        let data = {
          token: user?.token,
          folder: "avatar", type: "png"
        }
        // const imageRes = await upload("notification", temp, "webp", onProgress);

        const response = await fetch(API_URL + "/bucket", {
          method: 'PUT', // e.g., GET, POST, PUT, DELETE
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bareer " + user?.token
          },
          body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
        })
        const res = await response.json();
        if (res?.statusCode === 200 && res.data) {
          console.log('Signed URL response:', res?.data);
          // uploadToS3(res?.data, temp)
          try {
            // Fetch PUT request to upload the file
            const response = await fetch(res?.data, {
              method: 'PUT',
              headers: {
                'Content-Type': temp.type, // Set the content type of the file
              },
              body: temp, // The file to be uploaded
            });

            // Check for success
            if (response.status == 200) {
              body.image = res.data.split("amazonaws.com/")[1].split("?")[0];
              console.log('File uploaded successfully to S3', response);
            } else {
              console.error('Failed to upload file:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Error during upload:', error);
          }
        } else {
          console.error("Failed to get signed URL:", res);
          return; // Exit function if signed URL generation fails
        }
        post("/avatar", body)
          .then(res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.messgae);
              setIsAddForm(false);
              getData();
            } else {
              toast.error(res?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            toast.error('Something Went Wrong!');
            console.log('error while adding avatar', error);
          })
      } else
        toast.error('Please choose a avatar image!')
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/avatar/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Avatar" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        <Modal isOpen={isAddForm} toggle={() => setIsAddForm(!isAddForm)} centered={true} style={{ maxWidth: 540 }}>
          <ModalBody>
            <div className="me-3 position-relative">
              {file ?
                <>
                  {img ?
                    <>
                      <img
                        src={img}
                        alt=""
                        style={{ width: 360, height: 'auto' }}
                      // onClick={(e) => imageInput.current.click(e)}
                      />
                      <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                        onClick={() => { setFile(null); setImg("") }}
                      >
                        <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                      </div>
                    </>
                    :
                    <Cropper
                      style={{ height: 'auto', width: 500 }}
                      aspectRatio={1 / 1}
                      preview=".img-preview"
                      guides={true}
                      src={file}
                      ref={cropper}
                    />
                  }
                </>
                :
                <div style={{
                  position: 'relative',
                  width: 500,
                  height: 320,
                  border: '1px dashed ' + Utils.themeColor,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: Utils.themeColor,
                  fontSize: 22
                }} onClick={(e) => imageInput.current.click(e)}>
                  <span
                    style={{
                      padding: 5,
                      paddingLeft: 15, paddingRight: 15,
                      border: '1px solid ' + Utils.themeColor,
                      borderRadius: 5
                    }}
                  >Choose Image</span>
                  <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                  >
                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                  </div>
                </div>
              }
              <input type="file" id="file"
                ref={imageInput}
                onChange={onChangeFile}
                style={{ display: "none" }} />
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <div className="text-center mt-4">
                    {currentData ?
                      <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Update Avatar
                      </Button>
                      :
                      <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Add Avatar
                      </Button>
                    }
                  </div>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  Avatars you have added before, All the <code>Avatars </code>can be used by users as thier <code>Profile Image.</code>
                </CardSubtitle>
              </div>
              <div className="text-center" style={{ marginLeft: 'auto' }}>
                <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                  Add
                </Button>
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Avatar)
)
