import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Multiselect from 'multiselect-react-dropdown'


const Category = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [image, setImage] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [cats, setCats] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    get('/tags', { token: user?.token })
      .then(res => {
        setTags(res?.data);
      })
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/know/cat/list", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            res?.data?.map((item) => {
              item.isPlaying = false;
            })
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        name: 'Tags',
        cell: row => <>
          {row?.tags?.map((item) => (
            <span>{item?.title}, </span>
          ))}
        </>,
        selector: row => row.tags,
      },
      {
        name: 'Questions',
        selector: row => row.question?.length,
        maxWidth: '120px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button onClick={() => handlePaid(row)}
            title={"Paid"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data, tags]);

  const handleValidSubmit = async (e, v) => {
    console.log('values', v);
    if (selectedTags?.length < 1) {
      toast.error("Please select atleast one tag!");
      return;
    }
    let tempTags = [];
    selectedTags.forEach(item => {
      tempTags.push(item?._id);
    });
    let body = {
      ...v,
      tags: tempTags,
      alterLang: selectedLang,
      token: user?.token
    }
    if (currentData) {
      setLoading(true);
      body = { ...body, catId: currentData?._id };
      put("/know/cat", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      try {
        setLoading(true);
        post("/know/cat", body)
          .then((res) => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              getData();
              setIsAddForm(false);
            } else {
              toast.error(res?.error);
            }
          })
          .catch(err => {
            toast.error(err);
            setLoading(false);
          })
      } catch (error) {
        setLoading(false);
        console.log('error while adding category');
        toast.error("Something Went Wrong!");
      }
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    let tempTags = [];
    tags?.forEach((item) => {
      row?.tags?.forEach((tag) => {
        if (item?._id == tag?._id)
          tempTags.push(item);
      })
    });
    setSelectedTags(tempTags);
    if (row?.alterLang?.length)
      setSelectedLang(row?.alterLang);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      catId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/know/cat`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      catId: currentData?._id,
      token: user?.token
    }
    let url = `/know/cat`;
    if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/know/cat/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleSelectLang = (code, lang) => {
    let temp = Object.assign([], selectedLang);
    let already = temp.findIndex(x => x.code == code);
    if (already == -1) {
      temp.push({ code: code, title: "", lang });
    } else {
      temp.splice(already, 1);
    }
    // console.log("temp", temp);
    setSelectedLang(temp);
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }
  // console.log('image changed', image);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Know yourself Categories" />
        <Modal isOpen={isAddForm} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData ? `Update Category!` : `Add New Category!`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v, 'bank')
                }}
              >
                <Row>
                  <Col md={12}>
                    <div className="form-group mb-4">
                      <Label>Tags *</Label>
                      <Multiselect
                        options={tags} // Options to display in the dropdown
                        selectedValues={selectedTags} // Preselected value to persist in dropdown
                        onSelect={(list, item) => setSelectedTags(list)} // Function will trigger on select event
                        onRemove={(list, item) => setSelectedTags(list)} // Function will trigger on remove event
                        displayValue="title" // Property name to display in the dropdown options
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label="Title *"
                        className="form-control"
                        placeholder="Enter category title"
                        type="text"
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="desc"
                        label="Description *"
                        className="form-control"
                        type="textarea"
                        rows={4}
                        value={currentData?.desc}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Label>Alternative Languages</Label>
                <Row>
                  <div className='lang-checks mb-3'>
                    {user?.languages?.map((item) => (
                      <div className='check-group'>
                        <label for={item?.title} class="container">
                          {item?.title}
                          <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  {selectedLang?.map((item, index) => (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name={"title" + item?.lang}
                            label={"Title in " + item?.lang}
                            className="form-control"
                            placeholder={"Enter title in " + item?.lang}
                            type="text"
                            onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                            required
                            value={item?.title}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name={"desc" + item?.lang}
                            label={"Description in " + item?.lang}
                            className="form-control"
                            placeholder={"Enter description in " + item?.lang}
                            type="textarea"
                            rows={4}
                            onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                            required
                            value={item?.desc}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setIsAddForm(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                      <div>
                        <CardTitle className="h4">Category</CardTitle>
                        <CardSubtitle className="mb-3">
                          All Category you have added before.
                        </CardSubtitle>
                      </div>
                      {user?.role == 'Admin' &&
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                      }
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="table">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <DataTable
                              columns={columns}
                              data={data}
                              pagination
                              customStyles={{
                                headCells: {
                                  style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    maxWidth: 80
                                  },
                                },
                                cells: {
                                  style: {
                                    maxWidth: 50
                                  }
                                }
                              }}
                              conditionalRowStyles={[{
                                when: row => row?.seen?.length < 1,
                                style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                              }]}
                            // onRowClicked={(r, e) => seenNoti(r)}
                            />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <audio id="audio" src={currentAudio} />
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Category)
)