import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
  Modal,
  ModalBody
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { post, put, get, upload } from '../../helpers/api_helper';
import Utils from '../Utility';
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const AddUserForm = (props) => {
  const { user, data, loading, setLoading } = props;
  const [target, setTarget] = useState(data?.role ? data?.role : "");
  const [descLength, setDescLength] = useState(0);
  const [projects, setProjects] = useState([]);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const imageInput = useRef();
  const cropper = useRef();
  const [isCroping, setIsCroping] = useState(false);
  const [screens, setScreens] = useState([]);

  useEffect(() => {
    get("/screen/list", { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200)
          setScreens(res?.data);
      })
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.isNavigate)
        setIsNavigate(data?.isNavigate);
      if (data?.type)
        setType(data?.type)
      if (data?.target)
        setTarget(data?.target)
      if (data?.navigationType)
        setNavigationType(data?.navigationType);
      if(data?.image)
        setFile(data?.image)
    }
  }, [data])

  async function handleValidSubmit(event, values) {
    console.log('values from form', values, data);
    if (user) {
      let image = "";
      if (file?.length > 85) {
        const fileObj = await urltoFile(file, (new Date().getTime() + 300) + '.webp', 'image/webp')
        const form = new FormData();
        form.append("image", fileObj);
        const uploadedBanner = await upload("/notification/image_upload", form)
        // console.log('response from image upload', uploadedBanner);
        if (uploadedBanner?.statusCode == 200)
          image = uploadedBanner?.data;
      } else
        image = file;
      if (data?._id) {
        //updating user
        let body = {
          ...values,
          token: user?.token,
          notiId: data?._id
        };
        if (image)
          body = { ...body, image }
        setLoading(true);
        let url = "/notification/update";
        put(url, body)
          .then((json) => {
            console.log('response from updating noti', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              props.close(false);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            toast.error('Something Went Wrong!');
            setLoading(false);
          })
      } else {
        //adding new user
        let body = {
          ...values,
          addedBy: user?._id,
          triggerOn: values?.date + "T" + values?.time + ":00.000+00:00",
          token: user?.token,
        };
        if (image)
          body = { ...body, image }
        let url = "/notification/add";
        setLoading(true);
        post(url, body)
          .then((json) => {
            console.log('response from adding user', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              props.close(false);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            toast.error('Something Went Wrong!');
            setLoading(false);
          })
      }
    } else {
      toast.error('Need to Login again!');
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setImageToCrop(URL.createObjectURL(e.target.files[0]));
    // setImageChanged(true);
    setIsCroping(true);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const crop = async () => {
    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    setIsCroping(false);
    setFile(fileData);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="">
                  <div className="d-flex" style={{ marginLeft: 'auto' }}>
                    <div>
                      <CardTitle className="h4">{data ? 'Update' : 'Add New'} Notification</CardTitle>
                      <p className="card-title-desc">
                        This <code>Notification</code> Will be just saved not send, For sending to target you need to click on <code>send button.</code>
                      </p>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button color="primary" onClick={() => { props.setCurrentNoti(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Close
                      </Button>
                    </div>
                  </div>
                  {/* Image cropper modal */}
                  <Modal className="modal_form" isOpen={isCroping} toggle={() => { setIsCroping(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                      <Cropper
                        style={{ height: 'auto', width: 600 }}
                        aspectRatio={512 / 256}
                        preview=".img-preview"
                        guides={true}
                        src={imageToCrop}
                        ref={cropper}
                      />
                      <Row className='mt-4'>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor }}
                            onClick={() => { setIsCroping(false); setFile(null) }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            onClick={crop}
                            style={{ backgroundColor: Utils.themeColor }}
                          >
                            Crop
                          </button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="image"
                            label={"Notification Image"}
                            className="form-control"
                            placeholder={`Upload Notification Image`}
                            type="file"
                            onChange={onChangeFile}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="target"
                            label={"Notification Target"}
                            value={target}
                            className="form-control"
                            placeholder={`Select Notification Target`}
                            type="select"
                            required
                            onChange={e => setTarget(e.target.value)}
                          >
                            <option value="">Select Target</option>
                            <option value="All">All</option>
                            <option value="Unregistered">Unregistered</option>
                            <option value="Registered">Registered</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="type"
                            label={"Notification Type"}
                            value={type}
                            className="form-control"
                            placeholder={`Select Notification Type`}
                            type="select"
                            required
                            onChange={e => setType(e.target.value)}
                          >
                            <option value="">Select Type</option>
                            <option value="Instant">Instant</option>
                            <option value="Schedule">Schedule</option>
                          </AvField>
                        </div>
                      </Col>
                      {type == 'Schedule' &&
                        <>
                          <Col lg={3} md={3}>
                            <div className="form-group mb-4">
                              <AvField
                                name="date"
                                label={"Date"}
                                // value={data?.triggerOn}
                                className="form-control"
                                type="date"
                                required
                              >
                              </AvField>
                            </div>
                          </Col>
                          <Col lg={3} md={3}>
                            <div className="form-group mb-4">
                              <AvField
                                name="time"
                                label={"Time"}
                                // value={data?.triggerOn}
                                className="form-control"
                                type="time"
                                required
                              >
                              </AvField>
                            </div>
                          </Col>
                        </>
                      }
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="isNavigate"
                            label={"Navigation"}
                            value={isNavigate}
                            className="form-control"
                            type="select"
                            required
                            onChange={e => setIsNavigate(e.target.value)}
                          >
                            <option value="">Select Navigation</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </AvField>
                        </div>
                      </Col>
                      {isNavigate == 'Yes' &&
                        <Col lg={6} md={6}>
                          <div className="form-group mb-4">
                            <AvField
                              name="navigationType"
                              label={"Select Navigation Type"}
                              value={data?.navigationType}
                              className="form-control"
                              type="select"
                              onChange={(e) => setNavigationType(e.target.value)}
                              required
                            >
                              <option value="">Select Navigation Type</option>
                              <option value="internalPage">Internal App Screen</option>
                              <option value="inApp">In App Link</option>
                              <option value="outApp">External Web Link</option>
                            </AvField>
                          </div>
                        </Col>
                      }
                      {isNavigate == 'Yes' &&
                        <>
                          {navigationType == 'internalPage' ?
                            <Col lg={6} md={6}>
                              <div className="form-group mb-4">
                                <AvField
                                  name="screenName"
                                  label={"Select Navigation Type"}
                                  value={data?.screenName}
                                  className="form-control"
                                  type="select"
                                  required
                                >
                                  <option value="">Select App Screen</option>
                                  {screens?.map((item) => (
                                    <option value={item?.for}>{item?.title}</option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                            :
                            <Col lg={12} md={12}>
                              <div className="form-group mb-4">
                                <AvField
                                  name="link"
                                  label={"Web URL"}
                                  value={data?.link}
                                  className="form-control"
                                  placeholder={`Enter Url you need to open`}
                                  type="url"
                                  required
                                />
                              </div>
                            </Col>
                          }
                        </>
                      }
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="title"
                            label={"Notification Title"}
                            value={data?.title}
                            className="form-control"
                            placeholder={`Enter Title`}
                            type="text"
                            required
                          />
                        </div>
                      </Col>
                      <div className="form-group mb-4">
                        <AvField
                          name="body"
                          label={"Notification Description"}
                          value={data?.body}
                          className="form-control"
                          placeholder={`Enter Description`}
                          type="text"
                          required
                          validate={{ maxLength: { value: 200 } }}
                          onChange={(t) => setDescLength(t.target.value.length)}
                        />
                      </div>
                      <div className="mt-3" style={{ textAlign: 'end' }}>
                        <p>{descLength}/200</p>
                      </div>
                    </Row>

                    {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                    <div className="text-center mt-4">
                      {data?._id ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Notification
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Notification
                        </Button>
                      }
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AddUserForm;
