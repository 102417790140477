import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { saveAs } from 'file-saver';
// import './Card.scss';

const Table = (props) => {
  const { user, role, currentUser, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);

  useEffect(() => {
    if (user) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    get("/theme/weather/list", { token: user?.token })
      .then(json => {
        console.log('response from get project list', path);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Title',
        selector: row => row?.title,
      },
      {
        name: 'Updated At',
        selector: row => row.updatedAt,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateEdit(row, 'bank')}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDelete(row, 'bank')}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      setSelectedLang([]);
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    if (proj?.alterLang?.length) {
      let temp = [];
      proj?.alterLang?.forEach(element => {
        user?.languages?.forEach((item) => {
          if (element?.code == item?.code)
            temp.push({ ...element, lang: item?.title });
        })
      });
      setSelectedLang(temp);
    }
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentModalData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      weatherId: currentModalData?._id,
      isDeleted: true,
      token: user?.token
    }
    put(`/theme/weather`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      weatherId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/theme/weather`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      const body = {
        ...values,
        weatherId: currentModalData?._id,
        alterLang: selectedLang,
        token: user?.token
      }
      put("/theme/weather", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      const body = {
        ...values,
        token: user?.token
      }
      post("/theme/weather", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const handleSelectLang = (code, lang) => {
    let temp = Object.assign([], selectedLang);
    let already = temp.findIndex(x => x.code == code);
    if (already == -1) {
      temp.push({ code: code, title: "", lang });
    } else {
      temp.splice(already, 1);
    }
    // console.log("temp", temp);
    setSelectedLang(temp);
  }

  const handleAlterTitle = (index, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index].title = val;
    setSelectedLang(temp);
  }


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter title"
                          type="text"
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Label>Alternative Languages</Label>
                  <Row>
                    <div className='lang-checks'>
                      {user?.languages?.map((item) => (
                        <div className='check-group'>
                          <label for={item?.title} class="container">
                            {item?.title}
                            <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      ))}
                    </div>
                    {selectedLang?.map((item, index) => (
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name={"title" + item?.lang}
                            label={"Title in " + item?.lang}
                            className="form-control"
                            placeholder={"Enter title in " + item?.lang}
                            type="text"
                            onChange={(e) => handleAlterTitle(index, e.target.value)}
                            required
                            value={item?.title}
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>Themes </code> will be added according to {role}.
                    </CardSubtitle>
                  </div>
                </div>

                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <h5>Weathers</h5>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {/* <Button style={{ backgroundColor: Utils.themeColor, marginRight: 10 }} onClick={() => downloadZip()}>
                      Zip Images
                    </Button> */}
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                      Add
                    </Button>
                  </div>
                </div>

                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
