import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import Utils from '../../pages/Utility';
import { get } from "../../helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getNotiSuccess } from "../../store/actions";

const SidebarContent = props => {
  // console.log('props on slidebar content', props);
  const [subMenu, setSubMenu] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [isDealer, setIsDealer] = useState(false);
  const [isAutoLead, setIsLeadAuto] = useState(false);


  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, props?.user?.role])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    item.style.color = Utils.themeColor;
    item.style.fontWeight = 'bold';
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-airplay" style={{ color: Utils.themeColor }}></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {props.user?.role == 'Admin' &&
              <>
                <li>
                  <Link to="/users" className="waves-effect">
                    <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/artists" className="waves-effect">
                    <i className="mdi mdi-typewriter" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Artists")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/category" className="waves-effect">
                    <i className="mdi mdi-shape" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Categories")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/avatar" className="waves-effect">
                    <i className="mdi mdi-comment-account-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Avatars")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/banner" className="waves-effect">
                    <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tags" className="waves-effect">
                    <i className="mdi mdi-tag-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Tags")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/story" className="waves-effect">
                    <i className="mdi mdi-music-box" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Story")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ebook" className="waves-effect">
                    <i className="mdi mdi-book" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Ebook")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"} onClick={() => setSubMenu(!subMenu)}>
                    <i className="mdi mdi-television" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Shows")}</span>
                  </Link>
                  <ul className={"sub-menu"}>

                    <li><Link to="/show">
                      <i className="mdi mdi-television" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("All Shows")}</span>
                    </Link></li>
                    <li><Link to="/show/episodes">
                      <i className="mdi mdi-format-list-bulleted" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Episodes")}</span>
                    </Link></li>
                  </ul>
                </li>
                {/*<li>
                  <Link to={"/#"} className={"has-arrow waves-effect"} onClick={() => setSubMenu(!subMenu)}>
                    <i className="mdi mdi-compare" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Theme")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/weather">{props.t("Weather")}</Link></li>
                    <li><Link to="/theme">{props.t("Theme")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/wellness" className="waves-effect">
                    <i className="mdi mdi-meditation" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Wellness")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/yoga" className="waves-effect">
                    <i className="mdi mdi-yoga" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Yoga")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"} onClick={() => setSubMenu(!subMenu)}>
                    <i className="mdi mdi-soundcloud" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Harmony")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/harmony_mood">{props.t("Mood")}</Link></li>
                    <li><Link to="/harmony_category">{props.t("Sound Category")}</Link></li>
                    <li><Link to="/harmony_sound">{props.t("Sounds")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Know Yourself")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/know-yourself-category">{props.t("Category")}</Link></li>
                    <li><Link to="/know-yourself-intro">{props.t("Introduction")}</Link></li>
                    <li><Link to="/know-yourself-question">{props.t("Questions")}</Link></li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/category" className="waves-effect">
                    <i className="mdi mdi-shape" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Category")}</span>
                  </Link>
                </li> */}

                {/* <li>
                  <Link to="/store" className="waves-effect">
                    <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Image Store")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content" className="waves-effect">
                    <i className="mdi mdi-table-of-contents" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Content")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/games" className="waves-effect">
                    <i className="mdi mdi-gamepad" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Games")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/communities" className="waves-effect">
                    <i className="mdi mdi-home-group" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Communities")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Talk To Me")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/talk-intro">{props.t("Introduction")}</Link></li>
                    <li><Link to="/talk-category">{props.t("Category")}</Link></li>
                    <li><Link to="/talk-ques">{props.t("Question")}</Link></li>
                    <li><Link to="/talks">{props.t("Sessions")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/live_session" className="waves-effect">
                    <i className="mdi mdi-youtube-tv" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Live Session")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-cast-audio" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Podcast")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/podcast">{props.t("Podcasts")}</Link></li>
                    <li><Link to="/podcast_request">{props.t("Podcast Request")}</Link></li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/podcast" className="waves-effect">
                    <i className="mdi mdi-cast-audio" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Podcast Request")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/plans" className="waves-effect">
                    <i className="mdi mdi-currency-usd" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Plans")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/coupon" className="waves-effect">
                    <i className="mdi mdi-ticket-percent" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Coupon")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/social-media" className="waves-effect">
                    <i className="mdi mdi-play-network" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Social-Media")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/setting" className="waves-effect">
                    <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/screen" className="waves-effect">
                    <i className="mdi mdi-cellphone-text" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("App Screens")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/notification" className="waves-effect">
                    <i className="mdi mdi-bell" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
              </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  getNotiSuccess: PropTypes.func,
}

export default withRouter(withTranslation()(connect(mapStateToProps, { getNotiSuccess })(SidebarContent)))