import React, { useEffect, useState } from 'react';

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import "./style.css";

function UserProfile(props) {
    console.log('props on profile', props);
    const { userId, show, onCloseClick, user } = props;
    const [userData, setUserData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId)
            getUserData();
    }, [userId]);

    const getUserData = () => {
        if (!loading) {
            setLoading(true);
            post("/user/detail", { userId, token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setUserData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.log('error while getting user', err);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <Row style={{display:'flex', alignItems:'center'}}>
                    <Col md={4}>
                        <div className='profile_container'>
                            <img className='profile_image' style={{objectFit: 'cover'}} src={userData?.profile_picture ? userData?.profile_picture : require("../../assets/images/om.png")} />
                        </div>
                    </Col>
                    <Col md={6} className='mt-5'>
                        <span className='key'>Device : </span>
                    </Col>
                    <Col md={6} className='mt-5'>
                        <span className='value' style={{textTransform: 'capitalize'}}>{userData?.deviceType}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Email : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.email}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Phone : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.mobile}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>City : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.city}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>State : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.state}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Country : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.country}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Address : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.address}</span>
                    </Col>                   
                </Row>
            </ModalBody>
        </Modal>
    )
}
const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(UserProfile)
);