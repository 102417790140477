import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
import Utils from '../../Utility';
import { post, get, put, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import UserProfile from "../../../components/UserProfile";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(undefined);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/podcast/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'User',
        cell: (row) => <>
          <div onClick={() => handleViewUser(row?.user[0]?._id)}
            style={{ cursor: "pointer", width: 42, height: 42, border: `2px solid ${Utils.themeColor}`, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 42, marginRight: 5 }}>
            <img title={'image'} src={row?.user[0]?.profile_picture} style={{ width: 38, borderRadius: 38, height: 'auto', marginTop: 5, marginBottom: 5 }} />
          </div>
          <span style={{ cursor: "pointer" }} onClick={() => handleViewUser(row?.user[0]?._id)}>{row?.user[0]?.name}</span>
        </>,
        selector: row => row._id,
        maxWidth: '200px'
      },
      {
        name: 'Category',
        selector: row => row?.category[0]?.title,
        maxWidth: '130px'
      },
      {
        name: 'About Podcast',
        cell: (row) => <>
          <span title={row?.desc}>{row?.desc?.length < 200 ? row?.desc : row?.desc.substring(0, 410) + "..."}</span>
        </>,
      }
    ])
  }, [data]);

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleViewUser = (id) => {
    setCurrentUserId(id);
    setUserModal(true);
  }

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      communityId: currentData?._id,
      token: user?.token
    }
    let url = "/community";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/community/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <UserProfile
            userId={currentUserId}
            show={userModal}
            onCloseClick={() => setUserModal(false)}
            user={user}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role},<code> Anybody</code> can <code>request</code> for their episod. Only if they don't have any <code>panding request.</code> here. So keep maintaining <code>Status</code> as Rejected or Uploaded.
                    </CardSubtitle>
                  </div>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {/* <Button style={{ backgroundColor: Utils.themeColor, marginRight: 10 }} onClick={() => downloadZip()}>
                      Zip Images
                    </Button> */}
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                      Add
                    </Button>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={100}
                          paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                          },
                          ]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
            <audio id="audio" src={currentAudio} />
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
