import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';

const AddTheme = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [video, setVideo] = useState(undefined);
    const formRef = useRef();
    const cropper = useRef();
    const [cats, setCats] = useState([]);
    const [weather, setWeather] = useState([]);
    const [selectedWeather, setSelectedWeather] = useState([]);
    const [baseColor1, setBaseColor1] = useState("#000000");
    const [baseColor2, setBaseColor2] = useState("#077000");
    const [textColor, setTextColor] = useState("#ffffff");
    const [title, setTitle] = useState("");
    const [tagline, setTagline] = useState("");
    const [options, setOptions] = useState([{}, {}, {}, {}]);
    const [selectedLang, setSelectedLang] = useState([]);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values);
            let body = {
                ...values,
                options,
                alterLang: selectedLang,
                token: user?.token
            }
            setLoading(true);
            if (data?._id) {
                body = { ...body, quesId: data?._id }
                let url = "/know";
                console.log('body after upload images', body);
                put(url, body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property updated');
                            toast.success(json?.message);
                            props.close(false);
                            // props?.getData();
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                console.log('body after upload images', body);
                post("/know", body)
                    .then((json) => {
                        console.log('response from adding quote', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('product added');
                            toast.success(json?.message);
                            props.close(false);
                            // props?.getData();
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    })

            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        getWeather();
        if (data?.options?.length)
            setOptions(data?.options);
        if (data?.alterLang?.length) {
            data?.alterLang?.forEach((item) => {
                user?.languages?.forEach((lang) => {
                    if (item?.code == lang?.code)
                        item.lang = lang?.title
                })
            })
            setSelectedLang(data?.alterLang)
        }
    }, [data]);

    useEffect(() => {
        if (data?.weathers?.length > 0 && weather?.length > 0) {
            let selected = [];
            weather?.forEach(item => {
                data?.weathers?.forEach(we => {
                    if (item?._id == we)
                        selected.push(item);
                })
            });
            setSelectedWeather(selected);
        }
    }, [data, weather]);

    const getWeather = async () => {
        get("/know/cat/list", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200)
                    setCats(res?.data);
            });
    }

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        setFile(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    function formatMonth(monthNumber) {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return 'Invalid Month';
        }
    }

    const handleOptionInput = (to, index, val) => {
        let temp = Object.assign([{}, {}, {}, {}], options);
        temp[index][to] = val;
        setOptions(temp);
    }

    const handleSelectLang = (code, lang) => {
        let temp = Object.assign([], selectedLang);
        let already = temp.findIndex(x => x.code == code);
        if (already == -1) {
            temp.push({ code: code, title: "", options: [{}, {}, {}, {}], lang });
        } else {
            temp.splice(already, 1);
        }
        // console.log("temp", temp);
        setSelectedLang(temp);
    }

    const handleAlterTitle = (index, key, val, index2) => {
        let temp = Object.assign([], selectedLang);
        if (key == 'title')
            temp[index][key] = val;
        else {
            temp[index].options[index2].title = val;
        }
        setSelectedLang(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <h4 className="card-title">{data ? 'Update ' : 'Add a '} Question</h4>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                            ref={formRef}
                        >
                            <Row className='p-3'>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            value={data?.title}
                                            className="form-control"
                                            placeholder="Enter question title"
                                            type="text"
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="catId"
                                            label="Category *"
                                            value={data?.catId}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {cats?.map((item) => (
                                                <option value={item?._id}>{item?.title}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                {options?.map((item, index) => (
                                    <Row>
                                        <Col md={10}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"option" + index}
                                                    label={`Option ${index + 1} *`}
                                                    value={item?.title}
                                                    className="form-control"
                                                    placeholder={"Enter Option " + (index + 1)}
                                                    type="text"
                                                    onChange={(e) => handleOptionInput("title", index, e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"marks" + index}
                                                    label="Marks *"
                                                    value={item?.marks}
                                                    className="form-control"
                                                    placeholder="Enter Marks"
                                                    type="select"
                                                    onChange={(e) => handleOptionInput("marks", index, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select Marks</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                                <Label>Alternative Languages</Label>
                                <Row>
                                    <div className='lang-checks mb-3'>
                                        {user?.languages?.map((item) => (
                                            <div className='check-group'>
                                                <label for={item?.title} class="container">
                                                    {item?.title}
                                                    <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    {selectedLang?.map((item, index) => (
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name={"title" + item?.lang}
                                                        label={"Title in " + item?.lang}
                                                        className="form-control"
                                                        placeholder={"Enter title in " + item?.lang}
                                                        type="text"
                                                        onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                        required
                                                        value={item?.title}
                                                    />
                                                </div>
                                            </Col>
                                            <Row>
                                                {item.options?.map((item2, index2) => (
                                                    <Col md={6}>
                                                        <div className="form-group mb-4">
                                                            <AvField
                                                                name={"option" + index2}
                                                                label={`Option ${index2 + 1} in ${item?.lang} *`}
                                                                value={item2?.title}
                                                                className="form-control"
                                                                placeholder={"Enter Option " + (index2 + 1) + " in " + item?.lang}
                                                                type="text"
                                                                onChange={(e) => handleAlterTitle(index, "option", e.target.value, index2)}
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Row>
                                    ))}
                                </Row>
                                <Col lg={12} md={12}>
                                    <div className="text-center mt-4">
                                        {data ?
                                            <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                Update {role}
                                            </Button>
                                            :
                                            <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                Add {role}
                                            </Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

AddTheme.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddTheme)
)
