import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const LiveSession = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedLang, setSelectedLang] = useState([]);
  const imageInput = useRef();
  const cropper = useRef();

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/live/list", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) =>
          <img
            src={row?.image}
            width="80"
          />,
        maxWidth: '100px'
      },
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateLive(row)}
            title={row?.isLive ? "Stop Live" : "Go Live"}
            style={{ backgroundColor: row.isLive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.isLive ? 'Stop Live' : 'Go Live'}
            </span>
          </Button>
        </>,
        name: 'Live',
        selector: row => row.isLive,
        sortable: true,
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button onClick={() => handlePaid(row)}
            title={"Paid"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleValidSubmit = async (e, v) => {
    console.log('values', v);
    let body = {
      ...v,
      alterLang: selectedLang,
      token: user?.token
    }
    if (currentData) {
      setLoading(true);
      body = { ...body, liveId: currentData?._id };
      if (!currentData?.image) {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
        const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", temp);
        const imageRes = await upload("/live/image_upload", form);
        body = { ...body, image: imageRes?.data };
      } else {
        body = { ...body, image: currentData?.image };
      }
      put("/live", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      try {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
        const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", temp);
        const imageRes = await upload("/live/image_upload", form);
        body = { ...body, image: imageRes?.data };
        post("/live", body)
          .then((res) => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              getData();
              setIsAddForm(false);
            } else {
              toast.error(res?.error);
            }
          })
          .catch(err => {
            toast.error(err);
            setLoading(false);
          })
      } catch (error) {
        setLoading(false);
        console.log('error while adding category');
        toast.error("Something Went Wrong!");
      }
    }
  }

  const handleUpdateProj = (row) => {
    setFile(row?.image);
    setCurrentData(row);
    if (row?.alterLang?.length) {
      let langTemp = [];
      row?.alterLang?.forEach((item) => {
        user?.languages?.forEach(lang => {
          if (item?.code == lang?.code)
            langTemp.push({ ...item, lang: lang?.title });
        })
      })
      setSelectedLang(langTemp);
    }
    setIsAddForm(true);
  }

  const handleUpdateLive = (row) => {
    setCurrentData({ ...row, actionType: 'Live' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      liveId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/live`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      liveId: currentData?._id,
      token: user?.token
    }
    let url = `/live`;
    if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else if (currentData?.actionType == 'Live') {
      body = { ...body, isLive: !currentData?.isLive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/category/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleSelectLang = (code, lang) => {
    let temp = Object.assign([], selectedLang);
    let already = temp.findIndex(x => x.code == code);
    if (already == -1) {
      temp.push({ code: code, title: "", lang });
    } else {
      temp.splice(already, 1);
    }
    // console.log("temp", temp);
    setSelectedLang(temp);
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }

  const parseDate = (str) => {
    if (str) {
      let date = str?.split(":");
      let modify = date[0] + ":" + date[1];
      // console.log("date", str, date[0] + ":" + date[1]);
      return modify;
    } else
      return "";
  }

  // console.log('image changed', image);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Live Session" />
        <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData ? `Update Live Session!` : `Add New Live Session!`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      {file ?
                        <>
                          <CardTitle >Image</CardTitle>
                          <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                            {currentData?.image ?
                              <img src={file} style={{ width: 584, height: 'auto' }} />
                              :
                              <Cropper
                                style={{ height: 'auto', width: 584 }}
                                aspectRatio={2 / 1}
                                preview=".img-preview"
                                guides={true}
                                src={file}
                                ref={cropper}
                              />
                            }
                            <i className="mdi mdi-close" style={{
                              color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                              position: 'absolute', top: -16, right: -12, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                            }}
                              onClick={() => { setFile(null); if (currentData) setCurrentData({ ...currentData, image: undefined }) }}
                            />
                          </div>
                        </>
                        :
                        <AvField
                          name="fileInput"
                          label="Image"
                          className="form-control"
                          placeholder="upload bank logo"
                          type="file"
                          onChange={e => onChangeFile(e)}
                          required
                          multiple={false}
                          ref={imageInput}
                          accept="image/png, image/jpeg"
                        // value={currentModalData?.logo}
                        />
                      }
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label="Title *"
                        className="form-control"
                        placeholder="Enter live session title"
                        type="text"
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="url"
                        label="URL *"
                        className="form-control"
                        placeholder="Enter live session url"
                        type="url"
                        required
                        value={currentData?.url}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="scheduleAt"
                        label="Schedule At *"
                        className="form-control"
                        placeholder="Enter live session url"
                        type="datetime-local"
                        required
                        value={parseDate(currentData?.scheduleAt)}
                      />
                    </div>
                  </Col>
                </Row>
                <Label>Alternative Languages</Label>
                <Row>
                  <div className='lang-checks mb-3'>
                    {user?.languages?.map((item) => (
                      <div className='check-group'>
                        <label for={item?.title} class="container">
                          {item?.title}
                          <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  {selectedLang?.map((item, index) => (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name={"title" + item?.lang}
                            label={"Title in " + item?.lang}
                            className="form-control"
                            placeholder={"Enter title in " + item?.lang}
                            type="text"
                            onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                            required
                            value={item?.title}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setIsAddForm(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                      <div>
                        <CardTitle className="h4">Live Session</CardTitle>
                        <CardSubtitle className="mb-3">
                          All Live Sessions you have added before.
                        </CardSubtitle>
                      </div>
                      {user?.role == 'Admin' &&
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                      }
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="table">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <DataTable
                              columns={columns}
                              data={data}
                              pagination
                              customStyles={{
                                headCells: {
                                  style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    maxWidth: 80
                                  },
                                },
                                cells: {
                                  style: {
                                    maxWidth: 50
                                  }
                                }
                              }}
                              conditionalRowStyles={[{
                                when: row => row?.seen?.length < 1,
                                style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                              }]}
                            // onRowClicked={(r, e) => seenNoti(r)}
                            />
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(LiveSession)
)