import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility';
import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';


const AddYoga = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [animFile, setAnimFile] = useState(null);
    const [audio, setAudio] = useState(null);
    const [video, setVideo] = useState(null);
    const [files, setFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const imageInput = useRef();
    const stepImageInput = useRef();
    const cropper = useRef();
    const [cropperModal, setCropperModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [steps, setSteps] = useState([{}]);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentFileType, setCurrentFileType] = useState("file");
    const [yogaType, setYogaType] = useState("");
    const [selectedLang, setSelectedLang] = useState([]);

    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values, audio);
            if (data) {
                if (selectedTags.length < 1) {
                    toast.error('Please enter atleast one tag!');
                    return;
                }
                let stepTemp = Object.assign([], steps);
                if (yogaType == "Steps") {
                    if (!audio) {
                        toast.error("Audio file for background music is required!");
                        return;
                    }
                    let stepError = undefined;
                    stepTemp.forEach((item, index) => {
                        if (!item?.image)
                            stepError = index;
                    });
                    if (stepError != undefined) {
                        toast.error(`Please select Step ${stepError} Image!`);
                        return;
                    }
                } else {
                    if (!video) {
                        toast.error("Video file is required!");
                        return;
                    }
                }
                setLoading(true);
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let body = {
                    ...values,
                    yogaId: data?._id,
                    alterLang: selectedLang,
                    tags: selectTags,
                    token: user?.token
                }
                console.log('image file', file);
                if (file.substring(0, 4) == 'data') {
                    const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                    const temp = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", temp);
                    const imageRes = await upload("/yoga/image_upload", form);
                    body.image = imageRes?.data;
                }
                if (yogaType == 'Steps') {
                    await Promise.all(stepTemp.map(async (item, index) => {
                        if (item?.image.substring(0, 4) == 'data') {
                            const obj2 = await urltoFile(item?.image, new Date().getTime() + '.png', 'image/png');
                            const temp = await resizeFile(obj2);
                            const form2 = new FormData();
                            form2.append("image", temp);
                            const imageRes2 = await upload("/yoga/image_upload", form2);
                            item.image = imageRes2?.data;
                        }
                    }));
                    body.steps = stepTemp;
                    if (audio.substring(0, 4) == 'blob') {
                        const obj3 = await urltoFile(audio, new Date().getTime() + '.mp3', 'audio/mpeg');
                        const form3 = new FormData();
                        form3.append("image", obj3);
                        const audioRes = await upload("/yoga/image_upload", form3);
                        body.audio = audioRes?.data;
                    } else {
                        body.audio = audio;
                    }
                } else {
                    if (video.substring(0, 4) == 'blob') {
                        const obj3 = await urltoFile(video, new Date().getTime() + '.mp4', 'video/mp4');
                        const form3 = new FormData();
                        form3.append("image", obj3);
                        const videoRes = await upload("/yoga/image_upload", form3);
                        body.video = videoRes?.data;
                    } else {
                        body.video = video;
                    }
                }
                let url = "/yoga/update";
                console.log('body after upload images', body);
                put(url, body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                if (!file) {
                    toast.error("Placeholder image is required!");
                    return;
                }
                if (selectedTags.length < 1) {
                    toast.error('Please enter atleast one tag!');
                    return;
                }
                let stepTemp = Object.assign([], steps);
                if (yogaType == 'Steps') {
                    let stepError = undefined;
                    stepTemp.forEach((item, index) => {
                        if (!item?.image)
                            stepError = index;
                    });
                    if (stepError != undefined) {
                        toast.error(`Please select Step ${stepError} Image!`);
                        return;
                    }
                }
                setLoading(true);
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let image = "", audioUrl = "", videoUrl = "";
                const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const imageRes = await upload("/yoga/image_upload", form);
                image = imageRes?.data;
                if (yogaType == 'Steps') {
                    await Promise.all(stepTemp.map(async (item, index) => {
                        if (item?.image.substring(0, 4) == 'data') {
                            const obj2 = await urltoFile(item?.image, new Date().getTime() + '.png', 'image/png');
                            const temp = await resizeFile(obj2);
                            const form2 = new FormData();
                            form2.append("image", temp);
                            const imageRes2 = await upload("/yoga/image_upload", form2);
                            item.image = imageRes2?.data;
                        }
                    }))
                    const obj3 = await urltoFile(audio, new Date().getTime() + '.mp3', 'audio/mpeg');
                    const form3 = new FormData();
                    form3.append("image", obj3);
                    const audioRes = await upload("/yoga/image_upload", form3);
                    audioUrl = audioRes?.data;
                } else {
                    const obj3 = await urltoFile(video, new Date().getTime() + '.mp4', 'video/mp4');
                    const form3 = new FormData();
                    form3.append("image", obj3);
                    const videoRes = await upload("/yoga/image_upload", form3);
                    videoUrl = videoRes?.data;
                }
                console.log('url after upload images', image);
                let body = {
                    ...values,
                    token: user?.token,
                    image,
                    alterLang: selectedLang,
                    audio: audioUrl,
                    video: videoUrl,
                    tags: selectTags,
                    steps: yogaType == "Steps" ? stepTemp : []
                }
                console.log('body before adding wellness', body);
                let url = "/yoga/add";
                console.log('body after upload images', body);
                post(url, body)
                    .then((json) => {
                        console.log('response from adding product', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('product added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        console.log("data ", data);
        if (data) {
            if (data?.image)
                setFile(data?.image);
            setYogaType(data?.type);
            if (data?.type == "Steps") {
                if (data?.steps?.length) {
                    data?.steps?.forEach((step)=>{
                        if(step?.alterLang?.length){
                            step?.alterLang?.forEach((lang)=>{
                                user?.languages?.forEach((allLang)=>{
                                    if(allLang?.code==lang?.code)
                                        lang.lang = allLang?.title;
                                })
                            })
                        }
                    })
                    setSteps(data?.steps);
                }
                if (data?.audio)
                    setAudio(data?.audio);
            } else {
                if (data?.video)
                    setVideo(data?.video);
            }
            if (data?.tags?.length) {
                let temp = [];
                data?.tags?.map((item) => {
                    let found = tags.find(x => x?._id == item);
                    if (found)
                        temp.push(found);
                });
                setSelectedTags(temp);
            }
            if (data?.alterLang?.length)
                setSelectedLang(data?.alterLang);
        }
    }, [data, tags]);

    useEffect(() => {
        get('/tags', { token: user?.token })
            .then(res => {
                setTags(res?.data);
            })
    }, []);

    const onChangeFile = async (e, type) => {
        setCurrentFileType(type);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const removeImage = (to, i) => {
        if (to == 'step') {
            let temp = Object.assign([], steps);
            temp[i].image = null;
            setSteps(temp);
        } else {
            setFile(null);
            setCurrentFile(null);
        }
    }

    const cropImage = (e, v) => {
        setLoading(true);
        if (currentFileType == "file") {
            const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
            setFile(fileData);
        } else {
            const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
            let temp = Object.assign([], steps);
            temp[currentStep].image = fileData;
            setSteps(temp);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleStepInput = (val, to, index) => {
        let temp = Object.assign([], steps);
        temp[index][to] = val;
        setSteps(temp);
    }

    const handleRemoveStep = (index) => {
        let temp = Object.assign([], steps);
        temp.splice(index, 1);
        setSteps(temp);
    }

    const handleSelectLang = (code, lang) => {
        let temp = Object.assign([], selectedLang);
        let tempStep = Object.assign([], steps);
        let already = temp.findIndex(x => x.code == code);
        if (already == -1) {
            temp.push({ code: code, title: "", shortDesc: "", desc: "", lang });
        } else {
            temp.splice(already, 1);
        }
        tempStep?.forEach((item, index) => {
            if (item?.alterLang?.length > 0) {
                let already = item.alterLang.findIndex(x => x.code == code);
                if (already == -1) {
                    item.alterLang.push({ code: code, title: "", desc: "", lang });
                } else {
                    item.alterLang.splice(already, 1);
                }
            } else {
                item.alterLang = [{ code: code, title: "", desc: "", lang }];
            }
        })
        setSteps(tempStep);
        // console.log("temp", temp);
        setSelectedLang(temp);
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    const handleStepAlterTitle = (index, index2, key, val) => {
        let temp = Object.assign([], steps);
        temp[index].alterLang[index2][key] = val;
        setSteps(temp);
    }


    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={3} md={3} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 216 }}>
                                                        <img
                                                            src={file ? file : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage()}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={3} md={3}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 216 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add Banner</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput}
                                                                // accept='image/*'
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>

                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={currentFileType == 'file' ? 1 / 1 : 9 / 16}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <Label>Tags *</Label>
                                        <Multiselect
                                            options={tags} // Options to display in the dropdown
                                            selectedValues={selectedTags} // Preselected value to persist in dropdown
                                            onSelect={(list, item) => setSelectedTags(list)} // Function will trigger on select event
                                            // onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="title" // Property name to display in the dropdown options
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="avgTime"
                                            label="Average Time *"
                                            placeholder="Enter a average time to perform this yoga"
                                            className="form-control"
                                            value={data?.avgTime}
                                            type="number"
                                            validate={{ min: { value: 0 } }}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="type"
                                            label="Select Yoga content Type *"
                                            // value={data?.audio}
                                            className="form-control"
                                            type="select"
                                            required
                                            value={yogaType}
                                            onChange={(e) => setYogaType(e.target.value)}
                                        >
                                            <option value="">Select Yoga Type</option>
                                            <option value="Video">Video</option>
                                            <option value="Steps">Steps</option>
                                        </AvField>
                                    </div>
                                </Col>
                                {yogaType == "Steps" &&
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="audio"
                                                label="Background Music *"
                                                // value={data?.audio}
                                                className="form-control"
                                                type="file"
                                                accept="audio/mpeg"
                                                onChange={(e) => setAudio(URL.createObjectURL(e.target.files[0]))}
                                                required={data ? false : true}
                                            />
                                        </div>
                                    </Col>
                                }
                                {yogaType == "Video" &&
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="video"
                                                label="Yoga Video *"
                                                // value={data?.audio}
                                                className="form-control"
                                                type="file"
                                                accept="video/mp4"
                                                onChange={(e) => setVideo(URL.createObjectURL(e.target.files[0]))}
                                                required={data ? false : true}
                                            />
                                        </div>
                                    </Col>
                                }
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter the Yoga Title"
                                            value={data?.title}
                                            className="form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="shortDesc"
                                            label="Short Description *"
                                            placeholder="Enter a short description for listing"
                                            value={data?.shortDesc}
                                            className="form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="desc"
                                            label="Description *"
                                            placeholder="Enter detailed description about this yoga"
                                            value={data?.desc}
                                            className="form-control"
                                            type="textarea"
                                            rows={5}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Label>Alternative Languages</Label>
                            <Row>
                                <div className='lang-checks mb-3'>
                                    {user?.languages?.map((item) => (
                                        <div className='check-group'>
                                            <label for={item?.title} class="container">
                                                {item?.title}
                                                <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {selectedLang?.map((item, index) => (
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"title" + item?.lang}
                                                    label={"Title in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter title in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                    required
                                                    value={item?.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"shortDesc" + item?.lang}
                                                    label={"Short Description in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter short description in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "shortDesc", e.target.value)}
                                                    required
                                                    value={item?.shortDesc}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"desc" + item?.lang}
                                                    label={"Description in " + item?.lang}
                                                    placeholder={"Enter detailed description about this yoga in " + item?.lang}
                                                    value={item?.desc}
                                                    className="form-control"
                                                    type="textarea"
                                                    rows={5}
                                                    onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <input type="file" id="file"
                                ref={stepImageInput}
                                // accept='image/*'
                                onChange={(e) => onChangeFile(e, 'step')}
                                style={{ display: "none" }} />
                            <hr></hr>
                            {yogaType == "Steps" &&
                                <>
                                    {steps?.map((item, index) => (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <label><b>Step {index + 1}</b></label>
                                                    {item?.image ?
                                                        <Col lg={3} md={3} style={{ marginLeft: '2rem', marginTop: 20 }}>
                                                            <div className="me-3 position-relative" style={{ width: 171, height: 304 }}>
                                                                <img
                                                                    src={item?.image ? item?.image : avatar}
                                                                    alt=""
                                                                    style={{ width: '100%', height: '100%' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => removeImage("step", index)}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        :
                                                        <Col lg={3} md={3} style={{ marginLeft: '3rem', marginTop: 20 }}>
                                                            <div className="me-3 position-relative" style={{ width: 171, height: 304 }}>
                                                                <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                                    onClick={(e) => { stepImageInput.current.click(e); setCurrentStep(index) }}
                                                                >
                                                                    <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }} type="button">Add Image</button>
                                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    >
                                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Col>
                                                <Col md={6} className='mt-4'>
                                                    <Row>
                                                        <Col md={12}>
                                                            {steps?.length > 1 &&
                                                                <button
                                                                    onClick={() => handleRemoveStep(index)}
                                                                    style={{ float: 'right', backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }} type="button">Delete Step</button>
                                                            }
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="form-group mb-4">
                                                                <AvField
                                                                    name={"title" + index}
                                                                    label="Title *"
                                                                    placeholder="Enter step title"
                                                                    value={item?.title}
                                                                    className="form-control"
                                                                    type="text"
                                                                    required
                                                                    onChange={(e) => handleStepInput(e.target.value, "title", index)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="form-group mb-4">
                                                                <AvField
                                                                    name={"desc" + index}
                                                                    label="Description *"
                                                                    placeholder="Enter description"
                                                                    value={item?.desc}
                                                                    className="form-control"
                                                                    type="textarea"
                                                                    rows={4}
                                                                    required
                                                                    onChange={(e) => handleStepInput(e.target.value, "desc", index)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="form-group mb-4">
                                                                        <AvField
                                                                            name={"beginner" + index}
                                                                            label="Beginner Time *"
                                                                            placeholder="Enter time in minutes"
                                                                            value={item?.beginner}
                                                                            className="form-control"
                                                                            type="text"
                                                                            required
                                                                            onChange={(e) => handleStepInput(e.target.value, "beginner", index)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="form-group mb-4">
                                                                        <AvField
                                                                            name={"moderator" + index}
                                                                            placeholder="Enter time in minutes"
                                                                            label="Moderator *"
                                                                            value={item?.moderator}
                                                                            className="form-control"
                                                                            type="text"
                                                                            required
                                                                            onChange={(e) => handleStepInput(e.target.value, "moderator", index)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="form-group mb-4">
                                                                        <AvField
                                                                            name={"expert" + index}
                                                                            placeholder="Enter time in minutes"
                                                                            label="Expert *"
                                                                            value={item?.expert}
                                                                            className="form-control"
                                                                            type="text"
                                                                            required
                                                                            onChange={(e) => handleStepInput(e.target.value, "expert", index)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {item?.alterLang?.map((item2, index2) => (
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <AvField
                                                                            name={"title" + item2?.lang + index}
                                                                            label={"Title in " + item2?.lang}
                                                                            className="form-control"
                                                                            placeholder={"Enter title in " + item2?.lang}
                                                                            type="text"
                                                                            onChange={(e) => handleStepAlterTitle(index, index2, "title", e.target.value)}
                                                                            required
                                                                            value={item2?.title}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="form-group mb-4">
                                                                        <AvField
                                                                            name={"desc" + item2?.lang + index}
                                                                            label={"Description in " + item2?.lang}
                                                                            placeholder={"Enter detailed description about this yoga in " + item2?.lang}
                                                                            value={item2?.desc}
                                                                            className="form-control"
                                                                            type="textarea"
                                                                            rows={5}
                                                                            onChange={(e) => handleStepAlterTitle(index, index2, "desc", e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        {index == steps.length - 1 &&
                                                            <Col md={12}>
                                                                <button
                                                                    onClick={() => setSteps([...steps, { alterLang: [] }])}
                                                                    style={{ float: 'right', backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }} type="button">+ Add More Step</button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr className='mt-3'></hr>
                                        </>
                                    ))}
                                </>
                            }
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                <Button type="reset" style={{ border: 'none', backgroundColor: Utils.themeColor, marginRight: 20 }}
                                    onClick={() => { props.setCurrentData(null); props?.close(false) }}
                                >
                                    Cancel
                                </Button>
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddYoga.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddYoga)
)
