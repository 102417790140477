import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Progress } from "reactstrap"
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { getProfile } from '../../store/auth/profile/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Components
import LineChart from "./line-chart";
import RevenueChart from "./revenue-chart";
import SalesAnalytics from "./sales-analytics";
import DeviceAnalytics from "./devices";
import Products from "./product";
import LatestTransaction from "./latest-transaction";

//Import Image
import Revenue from './Revenue';
import { get } from "../../helpers/api_helper";
import Utils from "../Utility";
const { io } = require("socket.io-client");

const Dashboard = (props) => {
  const { user } = props;
  const [data, setData] = useState({ screen: [], dealers: [], moderator: [] });
  const [screens, setScreens] = useState([]);
  const [registeredUser, setRegisteredUser] = useState(0);
  const [unregisteredUser, setUnregisteredUser] = useState(0);
  const [ramCounter, setRamCounter] = useState(0);
  const [time, setTime] = useState(1);
  const [weekRegisterd, setWeekRegistered] = useState(0);
  const [weekUnregistered, setWeekUnregistered] = useState(0);
  const [cities, setCities] = useState([]);
  const [devices, setDevices] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // console.log('props on home ', user);
    if (user) {
      // getStats();
    }
  }, [user, time]);

  const getStats = () => {
    // console.log('calling get stat');
    get("/screen/stat", { token: user?.token })
      .then(json => {
        console.log('response from get stat', json);
        if (json?.statusCode == 200) {
          let appOpen = [], temp = [];
          json?.data?.screens?.map((item)=>{
            if(item?.for=="splash" || item?.for=="home")
              appOpen.push(item);
            else
              temp.push(item);              
          })
          setScreens(temp);
          setRegisteredUser(json?.data?.registeredUser);
          setUnregisteredUser(json?.data?.unregisteredUser);
          setWeekRegistered(json?.data?.weekRegisterd);
          setWeekUnregistered(json?.data?.weekUnregistered);
          setCities(json?.data?.userByCity);
          setDevices(json?.data?.devices);
          setProducts(json?.data?.products);
        }
      })
      .catch(error => {
        console.error('Error while getting stat', error);
      });

    const socket = io(Utils.API_URL + "/ram", { transports: ["websocket"], upgrade: false });
    socket.on("get", (res) => {
      console.log('socket on list', res);
      if (res?.statusCode == 200) {
        // console.log(res?.data)
        setRamCounter(res?.counter?.english);
      }
    });
    socket.on("connect", (socket2) => {
      console.log('socket connect', socket2);
      socket.emit("list", { auth: user?.token });
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to {Utils.projectName} Dashboard</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>
        {/* <Row style={{ justifyContent: 'right' }}>
          <Col lg={2}>
            <div style={{ width: '100%' }}>
              <AvForm stye={{ float: 'right' }}>
                <AvField
                  type="select"
                  name="time"
                  label={"Time"}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={1}>Today</option>
                  <option value={2}>Yesterday</option>
                  <option value={7}>Last 7 Days</option>
                  <option value={15}>Last 15 Days</option>
                  <option value={30}>Last 30 Days</option>
                  <option value={180}>Last 6 Month</option>
                  <option value={365}>Last 1 Year</option>
                </AvField>
              </AvForm>
            </div>
          </Col>
        </Row> */}
        {/* <Row className="mt-2">
          <Col lg={12}>
            <LineChart data={screens} />
          </Col>
        </Row> */}
        {/* <Row>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Users</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{registeredUser}</h4>
                    <p style={{ fontSize: 12 }}>Registered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{unregisteredUser}</h4>
                    <p style={{ fontSize: 12 }}>Unregistered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{registeredUser + unregisteredUser}</h4>
                    <p style={{ fontSize: 12 }}>Total</p>
                  </div>
                </div>
               
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <img src={require("../../assets/images/om.png")} style={{ width: 40, height: 40 }} />
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Ram Counter</div>

                  </div>
                </div>
                <h4 className="mt-4" style={{ textAlign: 'center' }}>{ramCounter}</h4>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-14 mt-2">Weekly Returning Users</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{weekRegisterd}</h4>
                    <p style={{ fontSize: 12 }}>Registered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{weekUnregistered}</h4>
                    <p style={{ fontSize: 12 }}>Unregistered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{weekRegisterd + weekUnregistered}</h4>
                    <p style={{ fontSize: 12 }}>Total</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <DeviceAnalytics source={devices} />
          </Col>
          <Col lg={6}>
            <SalesAnalytics source={cities} />
          </Col>
          <Col lg={6}>
            <Products source={products} />
          </Col>
        </Row> */}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  getProfile: PropTypes.func
}

const mapStateToProps = state => {
  // console.log('state at dashboard', state);
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { getProfile })(Dashboard)
)