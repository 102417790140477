import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { connect } from "react-redux";
import { getNotiSuccess } from "../../store/actions";
import { withRouter } from 'react-router-dom';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import './Card.scss';
import { toast } from "react-toastify";

const NotificationTable = (props) => {
  const { user, role, currentNoti } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { getNotiSuccess } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user)
      getNoti();
  }, [user]);

  const getNoti = () => {
    console.log('calling get user list get method');
    get("/notification/list", { token: user?.token })
      .then(json => {
        console.log('response from get user list', json);
        if (json.statusCode == 200) {
          setData(json?.data);
          // getNotiSuccess([{ count: json?.unseen }]);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting user list', error);
      })
  }

  const sendNoti = (noti) => {
    console.log('sending notification', noti);
    const body = {
      notiId: noti?._id,
      token: user?.token
    }
    post("/notification/trigger", body)
      .then((json) => {
        console.log('response from updating noti', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getNoti();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while adding user', error);
        toast.error(error);
      })

  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          {row?.image ?
            <img src={row?.image} style={{ width: 80, height: 40 }} alt={row?.title} />
            :
            <span></span>
          }
        </>,
        maxWidth:"100px"
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Title',
        selector: row => row.title,
        maxWidth:"250px"
      },
      {
        name: 'Description',
        cell: (row) => <>
          <span title={row?.body}>{row.body}</span>
        </>,
         maxWidth:"270px"
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Type',
        selector: row => row?.type,
        maxWidth:"100px"
      },
      {
        name: 'Trigger At',
        selector: row => row?.triggerOn,
        maxWidth:"130px"
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          {row.status == "Added" ?
            <>
              {row?.type == 'Instant' ?
                <Button onClick={() => sendNoti(row)}
                  title={"Send"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-send" style={{ fontSize: 20 }}></span></Button>
                :
                <Button
                  title={"Scheduled"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, cursor: 'default' }}><span className="mdi mdi-clock" style={{ fontSize: 20 }}></span></Button>
              }
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <Button onClick={() => handleDeleteProj(row)}
              title={"Delete"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
          }
          <Button onClick={() => handleDuplicateProj(row)}
            title={"Duplicate"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth:"150px"
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentNoti(proj);
    props.add(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('deleting proj', proj);
    props?.setCurrentNoti(proj);
    setDeleteModal(true);
  }

  const handleDuplicateProj = (proj) => {
    delete proj._id;
    props?.setCurrentNoti(proj);
    props.add(true);
  }

  const onDelete = () => {
    console.log('deleted', currentNoti);
    post("/notification/delete", { notiId: currentNoti?._id, token: user?.token })
      .then(json => {
        console.log('response from delete noti', json);
        if (json.statusCode == 200) {
          getNoti();
          setDeleteModal(false);
          props?.setCurrentNoti(null);
        }
      })
      .catch(error => {
        console.error('error while deleting noti', error);
      })
  }

  const seenNoti = (n) => {
    if (n?.seen?.length < 1) {
      const body = {
        notiId: n?._id,
        userId: user?._id,
        token: user?.token
      }
      post("/notification/seen", body)
        .then(json => {
          console.log('response from seen notification', json);
          if (json?.statusCode == 200) {
            getNoti();
            if (n?.leadId) {
              props?.history.push("/lead/" + n?.leadId);
            }
          }
        })
        .catch(error => {
          console.log('Erorr from seen notification', error);
        })
    } else {
      if (n?.leadId) {
        props?.history.push("/lead/" + n?.leadId);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">Notifications</CardTitle>
                    <CardSubtitle className="mb-3">
                      All Notifications you have added before.
                    </CardSubtitle>
                  </div>
                  {user?.role == 'Admin' &&
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      {!error &&
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                          Add
                        </Button>
                      }
                    </div>
                  }
                </div>
                {/* <div className="d-flex">

                  {data?.map((item, index) => {
                    return (
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>{item?.name}</p>
                            <span>{item?.intro}</span>
                          </div>
                          <div class="flip-card-back" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>Leads</p>
                            <span>Open : 10</span>
                            <span>Close : 5</span>
                            <span>Rejected : 2</span>
                            <div className="action">
                              <Button onClick={()=>props?.history.push("/lead/project/"+item?._id)}><i className="mdi mdi-eye"></i></Button>
                              <Button onClick={()=>handleDeleteProj(item)}><i className="mdi mdi-trash-can"></i></Button>
                              <Button onClick={()=>handleUpdateProj(item)}><i className="mdi mdi-pencil"></i></Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div> */}
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                  conditionalRowStyles={[{
                    when: row => row?.seen?.length < 1,
                    style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                  }]}
                  onRowClicked={(r, e) => seenNoti(r)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

// export default NotificationTable;

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

NotificationTable.propTypes = {
  getNotiSuccess: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, { getNotiSuccess })(NotificationTable));
