import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal, ModalBody, CardTitle, CardSubtitle,
  FormGroup, Label, Input, Badge
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import { post, get, put, uploadS3 } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import DataTable from "react-data-table-component";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const API_URL = Utils.API_URL;
import Select from 'react-select'

const Artists = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getTags = async () => {
    let url = '/tags';

    await get(url, { token: user?.token })
      .then(json => {

        setTags(json?.data);
        console.log('tagsList', json);
        console.log('in tag var', json?.data);
      }).catch(error => {
        console.log('error in fetching tags', error);
      })
  }

  const getData = async () => {
    let url = "/artist/list";
    get(url, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting artist list', error);
      });
  }



  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Name',
        cell: (row) => <>
          <span style={{}}>{row?.name}</span>
        </>,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('handleUpdateStatus', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    // console.log('deleted', currentData);
    let body = {
      artistId: currentData?._id,
      token: user?.token
    }
    let url = "/artist/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);

    setFile(URL.createObjectURL(e.target.files[0]));

    console.log('file', file);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onProgress = (progress) => {
    setProgress(progress);
  }




  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/avatar/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const addData = async (e, v) => {
    try {
      console.log('artist add data', v);


      let selectedTagsToSend = selectedTags.map(tag => (tag.value));

      console.log('tags to send ids', selectedTagsToSend);

      let body = {
        ...v,
        token: user?.token
      }

      if (file?.substring(0, 4) == "blob") {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
        const temp = await resizeFile(fileObj);
        let data = {
          token: user?.token,
          folder: "artist", type: "png"
        }
        // const imageRes = await upload("notification", temp, "webp", onProgress);

        const response = await fetch(API_URL + "/bucket", {
          method: 'PUT', // e.g., GET, POST, PUT, DELETE
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bareer " + user?.token
          },
          body: JSON.stringify(data), // Include data for POST, PUT, or PATCH
        })
        const res = await response.json();
        if (res?.statusCode === 200 && res.data) {
          console.log('Signed URL response:', res?.data);
          // uploadToS3(res?.data, temp)
          try {
            // Fetch PUT request to upload the file
            const response = await fetch(res?.data, {
              method: 'PUT',
              headers: {
                'Content-Type': temp.type, // Set the content type of the file
              },
              body: temp, // The file to be uploaded
            });

            // Check for success
            if (response.status == 200) {
              // body.image = res.data.split("amazonaws.com/")[1].split("?")[0];
                body = {
                  ...body,
                  image: res.data.split("amazonaws.com/")[1].split("?")[0]
                };
              console.log('File uploaded successfully to S3', response);
            } else {
              console.error('Failed to upload file:', response.status, response.statusText);
            }
          } catch (error) {
            console.error('Error during upload:', error);
          }
        } else {
          console.error("Failed to get signed URL:", res);
          return; // Exit function if signed URL generation fails
        }
        // const uploadedBanner = await uploadS3("artist", image2, "webp", onProgress)
        // console.log('response from image upload', uploadedBanner);
        // if (uploadedBanner?.statusCode == 200)
        //   body = {
        //     ...body,
        //     image: uploadedBanner?.data
        //   };
      }
      if (currentData?._id) {
        body = { ...body, artistId: currentData._id }
        put('/artist/update', body)
          .then(json => {
            if (json?.statusCode == 200) {
              getData();
              toast.success(json?.message);
            }
          })
          .catch(error => {
            console.log('error while adding artist', error);
            toast.error(error?.message);
          }).finally(() => {
            setIsAddForm(false);
          });
      }
      else {
        post('/artist/add', { ...body, tags: selectedTagsToSend })
          .then(json => {
            if (json?.statusCode == 200) {
              getData();
              toast.success(json?.message);
            }
          })
          .catch(error => {
            console.log('error while adding artist', error);
            toast.error(error?.message);
          }).finally(() => {
            setIsAddForm(false);
          });
      }

    } catch (e) {
      console.log('error while adding artist data', e);
    }
  }

  const options = tags.map(tag => ({
    value: tag._id,
    label: tag.title
  }));


  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Artists" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        <Modal
          onClosed={() => { setFile(null); setImg(""); setCurrentData(null); setSelectedTags([]) }}
          onOpened={() => {
            let alreadySelectedItems = [];
            if (currentData?.tags) {
              // let alreadySelectedItems = currentData?.tags.map(tag => {
              //   tags.includes(tag);
              // });

              tags.forEach((tag, index) => {
                if (currentData?.tags.includes(tag)) {
                  alreadySelectedItems.push(tag);
                  console.log('has ', tag);
                }
              })

              setSelectedTags(alreadySelectedItems);

              console.log('selected tags,', alreadySelectedItems);
            }
          }}
          isOpen={isAddForm} toggle={() => setIsAddForm(!isAddForm)}
          centered={true}
          style={{ maxWidth: 540 }}
        >
          <ModalBody>
            <div className="me-3 position-relative">
              {file ?
                <>
                  {img ?
                    <>
                      <img
                        src={img}
                        alt=""
                        style={{ width: 360, height: 'auto' }}
                      // onClick={(e) => imageInput.current.click(e)}
                      />
                      <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                        onClick={() => { setFile(null); setImg("") }}
                      >
                        <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                      </div>
                    </>
                    :
                    <Cropper
                      style={{ height: 'auto', width: 500 }}
                      aspectRatio={1 / 1}
                      preview=".img-preview"
                      guides={true}
                      src={file}
                      ref={cropper}
                    />
                  }
                </>
                :
                <center>
                  <div style={{
                    position: 'relative',
                    width: 300,
                    height: 300,
                    border: '1px dashed ' + Utils.themeColor,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Utils.themeColor,
                    fontSize: 22
                  }} onClick={(e) => imageInput.current.click(e)}>
                    <span
                      style={{
                        padding: 5,
                        paddingLeft: 15, paddingRight: 15,
                        border: '1px solid ' + Utils.themeColor,
                        borderRadius: 5
                      }}
                    >Choose Image</span>
                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}>
                      <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                    </div>
                  </div>
                </center>
              }
              <input type="file" id="file"
                ref={imageInput}
                onChange={onChangeFile}
                style={{ display: "none" }} />
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={addData}
              >
                <AvField
                  name="name"
                  label="Artist's Name"
                  required
                  value={currentData?.name}
                />
                <AvField name="desc" label="Description" required
                  value={currentData?.desc}
                />

                <Label>Gender</Label>
                <AvRadioGroup inline name="gender" value={currentData?.gender}>
                  <AvRadio label="Male" value="Male" />
                  <AvRadio label="Female" value="Female" />
                  <AvRadio label="Other" value="Other" />
                </AvRadioGroup>

                {/* <Select
                  isMulti
                  options={options}
                  label='Select Tags'
                  name="tags"
                  onChange={selectedOptions => {
                    setSelectedTags(selectedOptions);
                  }}
                  value = {selectedTags}

                  required
                /> */}

                {/* <AvField type="select" name="select-multiple" label="Tags" helpMessage="you can select multiple tags for an artist by pressing Shift + LMB" value={tags} multiple required>
                  {tags.map((field, index) => (
                    <option>
                      {field.title}
                    </option>
                  ))}
                </AvField> */}

                <Row>
                  <div className="text-center mt-4">
                    {currentData ?
                      <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Update Avatar
                      </Button>
                      :
                      <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Add Avatar
                      </Button>
                    }
                  </div>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  List of All Artists which contribute to Spiritual FM App
                </CardSubtitle>
              </div>
              <div className="text-center" style={{ marginLeft: 'auto' }}>
                <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                  Add
                </Button>
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Artists)
)
